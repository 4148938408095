var render = function render(){
  var _vm$product, _vm$product2, _vm$product2$meta, _vm$product2$meta$rev, _vm$product3, _vm$product3$meta, _vm$product3$meta$rev, _vm$product3$meta$rev2, _vm$product3$meta$rev3, _vm$product4, _vm$product5, _vm$product6, _vm$product7, _vm$product8, _vm$product9, _vm$product10, _vm$product11, _vm$product12, _vm$product13, _vm$product14, _vm$product15, _vm$product16, _vm$product17, _vm$product18, _vm$product19, _vm$product20, _vm$product21, _vm$summary, _vm$summary$reviews, _vm$summary$reviews$f, _vm$summary2, _vm$summary2$inquires, _vm$summary2$inquires2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.ready ? _c('client-page', {
    staticClass: "app--product"
  }, [_c('page-section', {
    staticClass: "page-section--first page-section--last"
  }, [_c('section', {
    staticClass: "product-intro"
  }, [_c('div', {
    staticClass: "product-intro__thumb"
  }, [_c('v-card', {
    staticClass: "product-intro__thumb-main",
    attrs: {
      "rounded": ""
    }
  }, [_c('v-img', {
    attrs: {
      "contain": "",
      "aspect-ratio": 1 / 1,
      "src": `/res/shop/products/${_vm.product._id}/${_vm.product.images[_vm.showImageIndex]}`
    }
  })], 1), _c('div', {
    staticClass: "pt-16px pt-sm-24px"
  }, [_c('v-row', {
    staticClass: "row--sm row-cols-5"
  }, _vm._l(5, function (i, index) {
    return _c('v-col', {
      key: `thumb-${index}`,
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-card', {
      staticClass: "product-intro__thumb-button overflow-hidden",
      attrs: {
        "role": "button",
        "aria-pressed": index == _vm.showImageIndex
      },
      on: {
        "click": function ($event) {
          _vm.showImageIndex = index;
        }
      }
    }, [_vm.product.images[index] ? _c('v-img', {
      attrs: {
        "cover": "",
        "aspect-ratio": 1 / 1,
        "src": `/res/shop/products/${_vm.product._id}/${_vm.product.images[index]}`
      }
    }) : _vm._e()], 1)], 1);
  }), 1)], 1)], 1), _c('div', {
    staticClass: "product-intro__info"
  }, [_c('div', {
    staticClass: "pb-16px pb-sm-24px"
  }, [_c('v-row', {
    staticClass: "row--xxs"
  }, [_vm.product.newly ? _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', {
    attrs: {
      "small": "",
      "color": "red lighten-5",
      "text-color": "red"
    }
  }, [_vm._v("신상품")])], 1) : _vm._e(), _vm.product.best ? _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', {
    attrs: {
      "small": "",
      "color": "light-blue lighten-5",
      "text-color": "light-blue"
    }
  }, [_vm._v("BEST")])], 1) : _vm._e()], 1)], 1), _c('div', {
    staticClass: "pb-16px pb-sm-24px"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', [_c('h3', {
    staticClass: "product-intro__name-text"
  }, [_vm._v(_vm._s(_vm.product.name))])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "pl-20px pl-md-40"
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('like-btn', {
    attrs: {
      "params": {
        _product: (_vm$product = _vm.product) === null || _vm$product === void 0 ? void 0 : _vm$product._id
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var active = _ref.active,
          toggle = _ref.toggle;
        return [_c('v-btn', {
          attrs: {
            "icon": "",
            "rounded": "",
            "outlined": "",
            "color": "grey lighten-3"
          },
          on: {
            "click": toggle
          }
        }, [active ? _c('span', {
          staticClass: "material-icons-outlined red--text"
        }, [_vm._v("favorite")]) : _c('span', {
          staticClass: "material-icons-outlined grey--text"
        }, [_vm._v("favorite_border")])])];
      }
    }], null, false, 3196431399)
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": "",
      "rounded": "",
      "outlined": "",
      "color": "grey lighten-3"
    },
    on: {
      "click": _vm.share
    }
  }, [_c('span', {
    staticClass: "material-icons-outlined grey--text"
  }, [_vm._v("share")])])], 1)], 1)], 1)])], 1), _c('div', {
    staticClass: "pt-16px pt-md-24px"
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('span', {
    staticClass: "material-icons-outlined primary--text"
  }, [_vm._v("star")]), _c('span', {
    staticClass: "font-size-14 font-weight-medium grey--text"
  }, [_vm._v(_vm._s((((_vm$product2 = _vm.product) === null || _vm$product2 === void 0 ? void 0 : (_vm$product2$meta = _vm$product2.meta) === null || _vm$product2$meta === void 0 ? void 0 : (_vm$product2$meta$rev = _vm$product2$meta.reviews) === null || _vm$product2$meta$rev === void 0 ? void 0 : _vm$product2$meta$rev.star__average) || 0).toFixed(1)))]), _c('span', {
    staticClass: "font-size-14 grey--text text--lighten-1 pl-4px"
  }, [_vm._v("(" + _vm._s(((_vm$product3 = _vm.product) === null || _vm$product3 === void 0 ? void 0 : (_vm$product3$meta = _vm$product3.meta) === null || _vm$product3$meta === void 0 ? void 0 : (_vm$product3$meta$rev = _vm$product3$meta.reviews) === null || _vm$product3$meta$rev === void 0 ? void 0 : (_vm$product3$meta$rev2 = _vm$product3$meta$rev.count__total) === null || _vm$product3$meta$rev2 === void 0 ? void 0 : (_vm$product3$meta$rev3 = _vm$product3$meta$rev2.format) === null || _vm$product3$meta$rev3 === void 0 ? void 0 : _vm$product3$meta$rev3.call(_vm$product3$meta$rev2)) || 0) + ")")])])])], 1), _c('v-divider'), _c('div', {
    staticClass: "py-16px py-sm-24px"
  }, [_c('v-row', {
    staticClass: "row--xs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_vm.product.discountPrice ? _c('span', {
    staticClass: "font-size-14 grey--text text--lighten-1 text-decoration-line-through"
  }, [_vm._v(_vm._s(_vm.product.price.format()))]) : _vm._e(), _vm.product.soldout || _vm.product.stock == 0 ? _c('em', {
    staticClass: "font-size-14 font-size-md-16 grey--text text--lighten-1"
  }, [_vm._v("품절")]) : _c('div', {
    staticClass: "d-flex align-center font-size-18 font-size-lg-28 font-weight-bold"
  }, [_vm.product.discountPrice ? _c('span', {
    staticClass: "primary--text mr-4px mr-md-8px"
  }, [_vm._v(_vm._s(_vm.product.discountRate) + "%")]) : _vm._e(), _c('span', {
    staticClass: "d-flex align-center grey--text text--darken-4"
  }, [_vm._v(_vm._s(_vm.product.salePrice.format())), _c('small', {
    staticClass: "font-size-14 font-weight-regular pl-2px"
  }, [_vm._v("원")])])])])], 1)], 1), _c('v-divider'), _c('div', {
    staticClass: "txt txt--xs py-16px py-sm-24px"
  }, [_c('tit-wrap-small', {
    attrs: {
      "title": "배송정보"
    }
  }), _c('div', [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "txt--light w-md-80px"
  }, [_vm._v("배송비")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('div', [_vm._v(_vm._s(_vm.product.deliveryMessage))])])], 1)], 1), _c('div', {
    staticClass: "pt-4px pt-md-8px"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "txt--light w-md-80px"
  }, [_vm._v("배송")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('div', [_vm._v("상품* 당일 13시까지 주문 건에 한하여, 당일 출고됩니다. "), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v("택배사 일정에 따라 2~3일 소요될 수 있습니다. (도서산간 제외)")])])], 1)], 1)], 1), _c('v-divider'), _c('product-view-benefits', _vm._b({}, 'product-view-benefits', {
    product: _vm.product
  }, false)), _c('div', {
    staticClass: "d-none d-lg-block"
  }, [(_vm$product4 = _vm.product) !== null && _vm$product4 !== void 0 && _vm$product4.optionsEnabled || (_vm$product5 = _vm.product) !== null && _vm$product5 !== void 0 && _vm$product5.suppliesEnabled || (_vm$product6 = _vm.product) !== null && _vm$product6 !== void 0 && _vm$product6.regularDeliveryEnabled ? _c('div', {
    staticClass: "txt txt--xs"
  }, [(_vm$product7 = _vm.product) !== null && _vm$product7 !== void 0 && _vm$product7.optionsEnabled ? _c('page-section', {
    staticClass: "page-section--xs pb-0",
    scopedSlots: _vm._u([{
      key: "containerImmersive",
      fn: function () {
        return [_c('tit-wrap-small', {
          attrs: {
            "title": "선택옵션"
          }
        }), _vm.ready ? _c('shop-product-options', _vm._b({
          model: {
            value: _vm.carts,
            callback: function ($$v) {
              _vm.carts = $$v;
            },
            expression: "carts"
          }
        }, 'shop-product-options', {
          product: _vm.product,
          deliveryType: _vm.deliveryType
        }, false)) : _vm._e()];
      },
      proxy: true
    }], null, false, 1560655926)
  }) : _vm._e(), (_vm$product8 = _vm.product) !== null && _vm$product8 !== void 0 && _vm$product8.suppliesEnabled ? _c('page-section', {
    staticClass: "page-section--xs pb-0",
    scopedSlots: _vm._u([{
      key: "containerImmersive",
      fn: function () {
        return [_c('tit-wrap-small', {
          attrs: {
            "title": "추가옵션"
          }
        }), _vm.ready ? _c('shop-product-supplies', _vm._b({
          model: {
            value: _vm.carts,
            callback: function ($$v) {
              _vm.carts = $$v;
            },
            expression: "carts"
          }
        }, 'shop-product-supplies', {
          product: _vm.product,
          deliveryType: _vm.deliveryType
        }, false)) : _vm._e()];
      },
      proxy: true
    }], null, false, 1526192319)
  }) : _vm._e(), (_vm$product9 = _vm.product) !== null && _vm$product9 !== void 0 && _vm$product9.regularDeliveryEnabled ? _c('page-section', {
    staticClass: "page-section--xs pb-0",
    scopedSlots: _vm._u([{
      key: "containerImmersive",
      fn: function () {
        return [_c('tit-wrap-small', {
          attrs: {
            "title": "배송옵션"
          }
        }), _vm.ready ? _c('shop-product-regular-delivery', _vm._b({
          on: {
            "deliveryType": _vm.setDeliveryType
          },
          model: {
            value: _vm.carts,
            callback: function ($$v) {
              _vm.carts = $$v;
            },
            expression: "carts"
          }
        }, 'shop-product-regular-delivery', {
          product: _vm.product,
          deliveryType: _vm.deliveryType
        }, false)) : _vm._e()];
      },
      proxy: true
    }], null, false, 2328890291)
  }) : _vm._e()], 1) : _vm._e(), _c('shop-product-carts', {
    model: {
      value: _vm.carts,
      callback: function ($$v) {
        _vm.carts = $$v;
      },
      expression: "carts"
    }
  }), _c('page-section', {
    staticClass: "page-section--xs pb-0",
    scopedSlots: _vm._u([{
      key: "containerImmersive",
      fn: function () {
        return [_c('v-row', {
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('div', {
          staticClass: "txt txt--xs"
        }, [_vm._v("총 상품 금액")])]), _c('v-spacer'), _c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('span', {
          staticClass: "d-flex align-center line-height-1 primary--text"
        }, [_c('strong', {
          staticClass: "font-size-20 font-size-lg-36"
        }, [_vm._v(_vm._s(_vm.totalPrice.format()))]), _c('span', {
          staticClass: "pl-2px"
        }, [_vm._v("원")])])])], 1)];
      },
      proxy: true
    }], null, false, 1200637966)
  }), _c('div', {
    staticClass: "btn-wrap"
  }, [_c('v-row', {
    staticClass: "row--sm"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-large",
    attrs: {
      "block": "",
      "outlined": "",
      "color": "primary"
    },
    on: {
      "click": _vm.addToCart
    }
  }, 'v-btn', {
    loading: _vm.loading
  }, false), [_vm._v("장바구니")])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-large",
    attrs: {
      "block": "",
      "color": "primary"
    },
    on: {
      "click": _vm.buyNow
    }
  }, 'v-btn', {
    loading: _vm.loading
  }, false), [_vm._v(_vm._s(_vm.deliveryType === "single" ? "바로구매" : "정기배송 신청하기"))])], 1)], 1)], 1)], 1), _c('v-bottom-navigation', {
    staticClass: "product-bottom-fixed",
    attrs: {
      "fixed": "",
      "height": "auto"
    }
  }, [_c('div', {
    staticClass: "w-100 pa-12px"
  }, [_c('v-row', {
    staticClass: "row--sm"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    staticClass: "v-size--xx-large",
    attrs: {
      "block": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.bottomNav = true;
      }
    }
  }, [_c('span', {
    staticClass: "white--text"
  }, [_vm._v("구매하기")])])], 1)], 1)], 1)]), _c('v-bottom-navigation', {
    staticClass: "product-bottom-fixed-view",
    attrs: {
      "input-value": _vm.bottomNav,
      "fixed": "",
      "height": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "rounded-0",
    attrs: {
      "block": "",
      "color": "transparent"
    },
    on: {
      "click": function ($event) {
        _vm.bottomNav = false;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "size": "24",
      "color": "grey"
    }
  }, [_vm._v("mdi-chevron-down")])], 1), _c('div', {
    staticClass: "w-100 pa-12px"
  }, [_c('div', {
    staticClass: "overflow-x-hidden overflow-y-auto",
    staticStyle: {
      "max-height": "50vh"
    }
  }, [(_vm$product10 = _vm.product) !== null && _vm$product10 !== void 0 && _vm$product10.optionsEnabled || (_vm$product11 = _vm.product) !== null && _vm$product11 !== void 0 && _vm$product11.suppliesEnabled || (_vm$product12 = _vm.product) !== null && _vm$product12 !== void 0 && _vm$product12.regularDeliveryEnabled ? _c('div', {
    staticClass: "txt txt--xs"
  }, [(_vm$product13 = _vm.product) !== null && _vm$product13 !== void 0 && _vm$product13.optionsEnabled ? _c('page-section', {
    staticClass: "page-section--xs py-0",
    scopedSlots: _vm._u([{
      key: "containerImmersive",
      fn: function () {
        return [_c('tit-wrap-small', {
          attrs: {
            "title": "선택옵션"
          }
        }), _vm.ready ? _c('shop-product-options', _vm._b({
          model: {
            value: _vm.carts,
            callback: function ($$v) {
              _vm.carts = $$v;
            },
            expression: "carts"
          }
        }, 'shop-product-options', {
          product: _vm.product
        }, false)) : _vm._e()];
      },
      proxy: true
    }], null, false, 903515870)
  }) : _vm._e(), (_vm$product14 = _vm.product) !== null && _vm$product14 !== void 0 && _vm$product14.suppliesEnabled ? _c('page-section', {
    staticClass: "page-section--xs pb-0",
    scopedSlots: _vm._u([{
      key: "containerImmersive",
      fn: function () {
        return [_c('tit-wrap-small', {
          attrs: {
            "title": "추가옵션"
          }
        }), _vm.ready ? _c('shop-product-supplies', _vm._b({
          model: {
            value: _vm.carts,
            callback: function ($$v) {
              _vm.carts = $$v;
            },
            expression: "carts"
          }
        }, 'shop-product-supplies', {
          product: _vm.product
        }, false)) : _vm._e()];
      },
      proxy: true
    }], null, false, 3362940055)
  }) : _vm._e(), (_vm$product15 = _vm.product) !== null && _vm$product15 !== void 0 && _vm$product15.regularDeliveryEnabled ? _c('page-section', {
    staticClass: "page-section--xs pb-0",
    scopedSlots: _vm._u([{
      key: "containerImmersive",
      fn: function () {
        return [_c('tit-wrap-small', {
          attrs: {
            "title": "배송옵션"
          }
        }), _vm.ready ? _c('shop-product-regular-delivery', _vm._b({
          model: {
            value: _vm.carts,
            callback: function ($$v) {
              _vm.carts = $$v;
            },
            expression: "carts"
          }
        }, 'shop-product-regular-delivery', {
          product: _vm.product
        }, false)) : _vm._e()];
      },
      proxy: true
    }], null, false, 1921295122)
  }) : _vm._e()], 1) : _vm._e(), _c('shop-product-carts', {
    model: {
      value: _vm.carts,
      callback: function ($$v) {
        _vm.carts = $$v;
      },
      expression: "carts"
    }
  })], 1), _c('page-section', {
    staticClass: "page-section--xs pb-0",
    scopedSlots: _vm._u([{
      key: "containerImmersive",
      fn: function () {
        return [_c('v-row', {
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('div', {
          staticClass: "txt txt--xs"
        }, [_vm._v("총 상품 금액")])]), _c('v-spacer'), _c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('span', {
          staticClass: "d-flex align-center line-height-1 primary--text"
        }, [_c('strong', {
          staticClass: "font-size-20 font-size-lg-36"
        }, [_vm._v(_vm._s(_vm.totalPrice.format()))]), _c('span', {
          staticClass: "pl-2px"
        }, [_vm._v("원")])])])], 1)];
      },
      proxy: true
    }], null, false, 1200637966)
  }), _c('div', {
    staticClass: "btn-wrap"
  }, [_c('v-row', {
    staticClass: "row--sm"
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-large primary--text",
    attrs: {
      "block": "",
      "outlined": "",
      "color": "primary"
    },
    on: {
      "click": _vm.addToCart
    }
  }, 'v-btn', {
    loading: _vm.loading
  }, false), [_vm._v("장바구니")])], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-large",
    attrs: {
      "block": "",
      "color": "primary"
    },
    on: {
      "click": _vm.buyNow
    }
  }, 'v-btn', {
    loading: _vm.loading
  }, false), [_c('span', {
    staticClass: "white--text"
  }, [_vm._v("바로구매")])])], 1)], 1)], 1)], 1)], 1)], 1)]), _c('section', {
    staticClass: "product-details"
  }, [_c('div', {
    directives: [{
      name: "scroll",
      rawName: "v-scroll",
      value: _vm.onScrollFixed,
      expression: "onScrollFixed"
    }],
    staticClass: "scroll-fixed-wrap"
  }, [_c('div', {
    staticClass: "scroll-fixed"
  }, [_c('v-container', [_c('div', {
    staticClass: "product-top-fixed"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-card', {
    attrs: {
      "rounded": ""
    }
  }, [_c('v-img', {
    attrs: {
      "contain": "",
      "width": "78",
      "aspect-ratio": 1 / 1,
      "src": `/res/shop/products/${_vm.product._id}/${_vm.product.images[_vm.showImageIndex]}`
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": ""
    }
  }, [_c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-medium mb-12px"
  }, [_vm._v(_vm._s(_vm.product.name))]), _c('div', {
    staticClass: "d-flex align-center font-size-20 font-size-md-28"
  }, [_vm.product.discountPrice ? _c('strong', {
    staticClass: "primary--text mr-8px"
  }, [_vm._v(_vm._s(_vm.product.discountRate) + "%")]) : _vm._e(), _vm.product.salePrice ? _c('strong', {
    staticClass: "d-inline-flex align-center"
  }, [_vm._v(_vm._s(_vm.product.salePrice.format())), _c('small', {
    staticClass: "font-size-14 font-weight-regular pl-2px"
  }, [_vm._v("원")])]) : _vm._e(), _vm.product.discountPrice ? _c('span', {
    staticClass: "font-size-14 text-decoration-line-through grey--text text--lighten-1 ml-8px"
  }, [_vm._v(_vm._s(_vm.product.price.format()) + "원")]) : _vm._e()])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm.productTopFixedBtn ? _c('v-btn', {
    staticClass: "v-size--xx-large min-w-200px",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        ((_vm.productTopFixedView = true) || true) && (_vm.productTopFixedBtn = false);
      }
    }
  }, [_vm._v("바로 구매")]) : _c('v-btn', {
    staticClass: "transparent",
    attrs: {
      "x-small": "",
      "icon": ""
    },
    on: {
      "click": function ($event) {
        ((_vm.productTopFixedView = false) || true) && (_vm.productTopFixedBtn = true);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "size": "24",
      "color": "grey"
    }
  }, [_vm._v("mdi-close")])], 1)], 1)], 1), _c('v-divider', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.productTopFixedView,
      expression: "productTopFixedView"
    }],
    staticClass: "my-24px"
  }), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.productTopFixedView,
      expression: "productTopFixedView"
    }],
    staticClass: "product-top-fixed-view"
  }, [_c('div', {
    staticClass: "overflow-hidden"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('div', {
    staticClass: "txt txt--xs"
  }, [_c('tit-wrap-small', {
    attrs: {
      "title": "배송정보"
    }
  }), _c('div', [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "txt--light w-md-80px"
  }, [_vm._v("배송비")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('div', [_vm._v(_vm._s(_vm.product.deliveryMessage))])])], 1)], 1), _c('div', {
    staticClass: "pt-4px pt-md-8px"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "txt--light w-md-80px"
  }, [_vm._v("배송")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('div', [_vm._v("상품* 당일 13시까지 주문 건에 한하여, 당일 출고됩니다. "), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v("택배사 일정에 따라 2~3일 소요될 수 있습니다. (도서산간 제외)")])])], 1)], 1)], 1)]), (_vm$product16 = _vm.product) !== null && _vm$product16 !== void 0 && _vm$product16.optionsEnabled || (_vm$product17 = _vm.product) !== null && _vm$product17 !== void 0 && _vm$product17.suppliesEnabled || (_vm$product18 = _vm.product) !== null && _vm$product18 !== void 0 && _vm$product18.regularDeliveryEnabled ? _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [(_vm$product19 = _vm.product) !== null && _vm$product19 !== void 0 && _vm$product19.optionsEnabled ? _c('page-section', {
    staticClass: "page-section--xs py-0",
    scopedSlots: _vm._u([{
      key: "containerImmersive",
      fn: function () {
        return [_c('tit-wrap-small', {
          attrs: {
            "title": "선택옵션"
          }
        }), _vm.ready ? _c('shop-product-options', _vm._b({
          model: {
            value: _vm.carts,
            callback: function ($$v) {
              _vm.carts = $$v;
            },
            expression: "carts"
          }
        }, 'shop-product-options', {
          product: _vm.product
        }, false)) : _vm._e()];
      },
      proxy: true
    }], null, false, 903515870)
  }) : _vm._e(), (_vm$product20 = _vm.product) !== null && _vm$product20 !== void 0 && _vm$product20.suppliesEnabled ? _c('page-section', {
    staticClass: "page-section--xs pb-0",
    scopedSlots: _vm._u([{
      key: "containerImmersive",
      fn: function () {
        return [_c('tit-wrap-small', {
          attrs: {
            "title": "추가옵션"
          }
        }), _vm.ready ? _c('shop-product-supplies', _vm._b({
          model: {
            value: _vm.carts,
            callback: function ($$v) {
              _vm.carts = $$v;
            },
            expression: "carts"
          }
        }, 'shop-product-supplies', {
          product: _vm.product
        }, false)) : _vm._e()];
      },
      proxy: true
    }], null, false, 3362940055)
  }) : _vm._e(), (_vm$product21 = _vm.product) !== null && _vm$product21 !== void 0 && _vm$product21.regularDeliveryEnabled ? _c('page-section', {
    staticClass: "page-section--xs pb-0",
    scopedSlots: _vm._u([{
      key: "containerImmersive",
      fn: function () {
        return [_c('tit-wrap-small', {
          attrs: {
            "title": "배송옵션"
          }
        }), _vm.ready ? _c('shop-product-regular-delivery', _vm._b({
          model: {
            value: _vm.carts,
            callback: function ($$v) {
              _vm.carts = $$v;
            },
            expression: "carts"
          }
        }, 'shop-product-regular-delivery', {
          product: _vm.product
        }, false)) : _vm._e()];
      },
      proxy: true
    }], null, false, 1921295122)
  }) : _vm._e()], 1) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('shop-product-carts', {
    staticClass: "pt-0",
    model: {
      value: _vm.carts,
      callback: function ($$v) {
        _vm.carts = $$v;
      },
      expression: "carts"
    }
  }), _c('page-section', {
    staticClass: "page-section--xs pb-0",
    scopedSlots: _vm._u([{
      key: "containerImmersive",
      fn: function () {
        return [_c('v-row', {
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('div', {
          staticClass: "txt txt--xs"
        }, [_vm._v("총 상품 금액")])]), _c('v-spacer'), _c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('span', {
          staticClass: "d-flex align-center line-height-1 primary--text"
        }, [_c('strong', {
          staticClass: "font-size-20 font-size-lg-36"
        }, [_vm._v(_vm._s(_vm.totalPrice.format()))]), _c('span', {
          staticClass: "pl-2px"
        }, [_vm._v("원")])])])], 1)];
      },
      proxy: true
    }], null, false, 1200637966)
  }), _c('div', {
    staticClass: "btn-wrap"
  }, [_c('v-row', {
    staticClass: "row--sm"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-large",
    attrs: {
      "block": "",
      "outlined": "",
      "color": "primary"
    },
    on: {
      "click": _vm.addToCart
    }
  }, 'v-btn', {
    loading: _vm.loading
  }, false), [_vm._v("장바구니")])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-large",
    attrs: {
      "block": "",
      "color": "primary"
    },
    on: {
      "click": _vm.buyNow
    }
  }, 'v-btn', {
    loading: _vm.loading
  }, false), [_vm._v("바로구매")])], 1)], 1)], 1)], 1)], 1)], 1)])], 1), _c('scrollactive', {
    staticClass: "v-tabs",
    attrs: {
      "offset": _vm.$vuetify.breakpoint.lgAndUp ? 186 : 100,
      "scrollOffset": _vm.$vuetify.breakpoint.lgAndUp ? 186 : 100,
      "highlightFirstItem": true,
      "active-class": "v-tab--active"
    }
  }, [_c('router-link', {
    staticClass: "scrollactive-item v-tab",
    attrs: {
      "to": "#review"
    }
  }, [_vm._v("상품평"), _c('span', {
    staticClass: "d-none d-lg-inline-block"
  }, [_vm._v("(" + _vm._s(((_vm$summary = _vm.summary) === null || _vm$summary === void 0 ? void 0 : (_vm$summary$reviews = _vm$summary.reviews) === null || _vm$summary$reviews === void 0 ? void 0 : (_vm$summary$reviews$f = _vm$summary$reviews.format) === null || _vm$summary$reviews$f === void 0 ? void 0 : _vm$summary$reviews$f.call(_vm$summary$reviews)) || 0) + ")")])]), _c('router-link', {
    staticClass: "scrollactive-item v-tab",
    attrs: {
      "to": "#detail"
    }
  }, [_vm._v("상품상세")]), _c('router-link', {
    staticClass: "scrollactive-item v-tab",
    attrs: {
      "to": "#qna"
    }
  }, [_vm._v("상품문의"), _c('span', {
    staticClass: "d-none d-lg-inline-block"
  }, [_vm._v("(" + _vm._s(((_vm$summary2 = _vm.summary) === null || _vm$summary2 === void 0 ? void 0 : (_vm$summary2$inquires = _vm$summary2.inquires) === null || _vm$summary2$inquires === void 0 ? void 0 : (_vm$summary2$inquires2 = _vm$summary2$inquires.format) === null || _vm$summary2$inquires2 === void 0 ? void 0 : _vm$summary2$inquires2.call(_vm$summary2$inquires)) || 0) + ")")])]), _c('router-link', {
    staticClass: "scrollactive-item v-tab",
    attrs: {
      "to": "#delivery"
    }
  }, [_vm._v("배송/교환/반품 정보")])], 1)], 1)], 1)]), _c('shop-product-reviews', _vm._b({
    on: {
      "setSummaryReviews": function (count) {
        return _vm.summary.reviews = count;
      }
    }
  }, 'shop-product-reviews', {
    product: _vm.product
  }, false)), _c('div', {
    staticClass: "product-details__content",
    attrs: {
      "id": "detail"
    }
  }, [_c('section', {
    staticClass: "product-sub",
    domProps: {
      "innerHTML": _vm._s(_vm.product.content)
    }
  })]), _c('shop-product-inquires', {
    attrs: {
      "product": _vm.product
    },
    on: {
      "setSummaryInquires": function (count) {
        return _vm.summary.inquires = count;
      }
    }
  }), _c('div', {
    staticClass: "product-details__content",
    attrs: {
      "id": "delivery"
    }
  }, [_c('div', {
    staticClass: "pb-20px"
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('h3', {
    staticClass: "tit tit--xxs"
  }, [_vm._v("배송/교환/반품 정보")])])], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('div', {
    staticClass: "txt txt--xs"
  }, [_c('tit-wrap-small', {
    attrs: {
      "title": "반품"
    }
  }), _c('div', [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "txt--light w-md-80px"
  }, [_vm._v("반품신청​")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('div', [_vm._v("구매자 책임사유시 반품/배송비 결제​")])])], 1)], 1), _c('div', {
    staticClass: "pt-4px pt-md-8px"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "txt--light w-md-80px"
  }, [_vm._v("발송하기")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('div', [_vm._v("1~2일 이내 택배기사 방문​")])])], 1)], 1), _c('div', {
    staticClass: "pt-4px pt-md-8px"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "txt--light w-md-80px"
  }, [_vm._v("반품접수완료​")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('div', [_vm._v("리본바이옴에 반품상품 전달​")])])], 1)], 1), _c('div', {
    staticClass: "pt-4px pt-md-8px"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "txt--light w-md-80px"
  }, [_vm._v("반품완료")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('div', [_vm._v("확인 후 환불처리​")])])], 1)], 1)], 1)]), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('div', {
    staticClass: "txt txt--xs"
  }, [_c('tit-wrap-small', {
    attrs: {
      "title": "교환"
    }
  }), _c('div', [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "txt--light w-md-80px"
  }, [_vm._v("교환신청")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('div', [_vm._v("구매자 책임사유시 반품/배송비 결제​")])])], 1)], 1), _c('div', {
    staticClass: "pt-4px pt-md-8px"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "txt--light w-md-80px"
  }, [_vm._v("발송하기")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('div', [_vm._v("1~2일 이내 택배기사 방문​")])])], 1)], 1), _c('div', {
    staticClass: "pt-4px pt-md-8px"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "txt--light w-md-80px"
  }, [_vm._v("교환접수")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('div', [_vm._v("리본바이옴에 교환상품 전달​")])])], 1)], 1), _c('div', {
    staticClass: "pt-4px pt-md-8px"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "txt--light w-md-80px"
  }, [_vm._v("상품재배송​")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('div', [_vm._v("확인 후 교환​")])])], 1)], 1), _c('div', {
    staticClass: "pt-4px pt-md-8px"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "txt--light w-md-80px"
  }, [_vm._v("교환완료")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('div', [_vm._v("완료")])])], 1)], 1)], 1)]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('div', {
    staticClass: "txt txt--xs"
  }, [_c('tit-wrap-small', {
    attrs: {
      "title": "주의사항"
    }
  }), _c('div', [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("-")]), _c('v-col', {
    attrs: {
      "cols": ""
    }
  }, [_vm._v(" 미성년자가 물품을 구매하는 경우, 법정대리인이 동의하지 않으면 미성년자 본인 또는 법정대리인이 구매를 취소할 수 있습니다.​​ ")])], 1)], 1), _c('div', {
    staticClass: "pt-4px pt-md-8px"
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("-")]), _c('v-col', {
    attrs: {
      "cols": ""
    }
  }, [_vm._v(" 안전검사 대상 공산품’을 구매하시는 경우 품질경영 및 공산품 안전관리법에 따른 안전검사 표시가 있는 제품인지 확인하시기 바랍니다.​ ")])], 1)], 1)], 1)])], 1)], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    staticClass: "product-details__sidebar"
  }, [_c('div', {
    staticClass: "product-sidebar",
    attrs: {
      "data-sidebar-fixed": "off"
    }
  }, [_c('div', {
    staticClass: "product-sidebar__contents"
  }, [_c('button', {
    staticClass: "product-sidebar__close",
    attrs: {
      "aria-pressed": "false"
    }
  }, [_c('span', {
    staticClass: "blind"
  }, [_vm._v("옵션 닫기")]), _c('i', {
    staticClass: "arrow"
  })]), _c('div', {
    staticClass: "product-sidebar__top"
  }, [_c('span', {
    staticClass: "product-sidebar__title"
  }, [_vm._v(_vm._s(_vm.product.name))]), _vm.ready ? _c('shop-product-options', _vm._b({
    attrs: {
      "sidebar": ""
    },
    model: {
      value: _vm.carts,
      callback: function ($$v) {
        _vm.carts = $$v;
      },
      expression: "carts"
    }
  }, 'shop-product-options', {
    product: _vm.product
  }, false)) : _vm._e(), _vm.ready ? _c('shop-product-supplies', _vm._b({
    attrs: {
      "sidebar": ""
    },
    model: {
      value: _vm.carts,
      callback: function ($$v) {
        _vm.carts = $$v;
      },
      expression: "carts"
    }
  }, 'shop-product-supplies', {
    product: _vm.product
  }, false)) : _vm._e(), _c('div', {
    staticClass: "product-sidebar__option-box-group"
  }, _vm._l(_vm.carts, function (cart, index) {
    return _c('div', {
      key: index,
      staticClass: "product-option-box"
    }, [_c('button', {
      staticClass: "product-option-box__delete",
      attrs: {
        "type": "button"
      },
      on: {
        "click": function ($event) {
          return _vm.carts.splice(index, 1);
        }
      }
    }, [_c('span', {
      staticClass: "blind"
    }, [_vm._v("삭제")])]), _c('span', {
      staticClass: "product-option-box__title"
    }, [_vm._v(_vm._s(cart.name) + " "), cart.stock == 0 ? _c('span', [_vm._v("(품절)")]) : _vm._e()]), _c('div', {
      staticClass: "product-option-box__flex"
    }, [_c('div', {
      staticClass: "spinner"
    }, [_c('button', {
      staticClass: "spinner__minus",
      attrs: {
        "type": "button"
      },
      on: {
        "click": function ($event) {
          return _vm.decAmount(cart);
        }
      }
    }), _c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: cart.amount,
        expression: "cart.amount"
      }],
      staticClass: "spinner__num",
      attrs: {
        "type": "number",
        "disalbed": !cart.stock
      },
      domProps: {
        "value": cart.amount
      },
      on: {
        "change": function ($event) {
          return _vm.changeAmount(cart);
        },
        "input": function ($event) {
          if ($event.target.composing) return;
          _vm.$set(cart, "amount", $event.target.value);
        }
      }
    }), _c('button', {
      staticClass: "spinner__plus",
      attrs: {
        "type": "button"
      },
      on: {
        "click": function ($event) {
          return _vm.incAmount(cart);
        }
      }
    })]), _c('div', {
      staticClass: "product-option-box__price"
    }, [_c('span', {
      staticClass: "price"
    }, [_c('em', {
      staticClass: "num"
    }, [_vm._v(_vm._s((cart.salePrice * cart.amount).format()) + "원")])])])])]);
  }), 0)], 1), _c('div', {
    staticClass: "product-sidebar__bottom"
  }, [_c('div', {
    staticClass: "product-sidebar__total"
  }, [_c('span', {
    staticClass: "price"
  }, [_vm._v(" 총 "), _c('em', {
    staticClass: "num"
  }, [_vm._v(_vm._s(_vm.totalPrice.format()))]), _vm._v(" 원 ")])]), _c('div', {
    staticClass: "product-sidebar__buttons"
  }, [_c('a', _vm._b({
    staticClass: "button button--border-primary font-nanum-m",
    on: {
      "click": _vm.addToCart
    }
  }, 'a', {
    loading: _vm.loading
  }, false), [_c('span', [_vm._v("장바구니")])]), _c('a', _vm._b({
    staticClass: "button button--primary font-nanum-m",
    on: {
      "click": _vm.buyNow
    }
  }, 'a', {
    loading: _vm.loading
  }, false), [_c('span', [_vm._v("바로구매")])])])])])])])], 1), _c('modal-cart', {
    ref: "modal-cart"
  })], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }