<template>
    <div class="border-t py-20px py-md-32px">
        <!-- head -->
        <div class="pb-16px pb-md-20px">
            <v-row class="row--sm">
                <v-col cols="12" md="">
                    <v-row align="center" class="row--xs">
                        <v-col cols="auto">
                            <v-card rounded="circle" color="#d9d9d9" class="w-40px">
                                <v-img :aspect-ratio="1 / 1">
                                    <div class="d-flex align-center justify-center h-100">
                                        <span class="material-icons font-size-20 font-size-md-24 white--text">person</span>
                                    </div>
                                </v-img>
                            </v-card>
                        </v-col>
                        <v-col cols="auto">
                            <div class="txt txt--xs txt--dark font-weight-medium">
                                <span>{{ value?.user?.username?.slice?.(0, 3)?.padEnd?.(value?.user?.username?.length, "*") || "탈퇴회원" }}</span>
                            </div>
                            <div class="txt txt--xs txt--light">{{ value?.productName || "-" }} / {{ value?.purchaseName || "-" }}</div>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" md="auto">
                    <div class="d-flex align-center">
                        <span class="txt txt--xs txt--light"> {{ value?.createdAt?.toDateTime?.() || "-" }} </span>
                        <!-- <span class="txt txt--xs txt--light px-4px">·</span>
                        <v-btn small text color="grey lighten-1" class="v-btn--text-fit">신고</v-btn> -->
                    </div>
                </v-col>
            </v-row>
        </div>

        <!-- body -->
        <v-row>
            <v-col cols="12" sm="">
                <div class="pb-4px">
                    <v-rating half-increments readonly size="18" color="primary" background-color="primary" :value="value?.star || 0" />
                </div>
                <div class="txt txt--xs">
                    {{ value?.content || "-" }}
                </div>
            </v-col>
            <v-col cols="12" sm="auto">
                <review-images-dialog v-bind="{ _product: value?._product, _review: value?._id, _image: value?._images?.[0] }">
                    <template #activator="{ attrs, on }">
                        <v-card rounded="md" class="w-sm-60px ml-auto overflow-hidden" v-bind="attrs" v-on="on">
                            <v-img cover :aspect-ratio="1 / 1" v-bind="value?.images?.[0]" />
                        </v-card>
                    </template>
                </review-images-dialog>
            </v-col>
        </v-row>

        <!-- 관리자 -->
        <!-- 반복 -->
        <div class="pt-20px pt-md-32px" v-if="value?.reply">
            <!-- 관리자 head -->
            <div class="pb-8px pb-md-12px">
                <v-row class="row--sm">
                    <v-col cols="12" md="">
                        <v-row align="center" class="row--xs">
                            <v-col cols="auto">
                                <v-card rounded="circle" color="#d9d9d9" class="w-40px">
                                    <v-img :aspect-ratio="1 / 1">
                                        <div class="d-flex align-center justify-center h-100">
                                            <span class="material-icons font-size-20 font-size-md-24 white--text">person</span>
                                        </div>
                                    </v-img>
                                </v-card>
                            </v-col>
                            <v-col cols="auto">
                                <div class="txt txt--xs txt--dark font-weight-medium"><span>판매자</span></div>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="auto">
                        <div class="d-flex align-center">
                            <span class="txt txt--xs txt--light">{{ value?.repliedAt?.toDateTime?.() }}</span
                            ><span class="txt txt--xs txt--light px-4px">·</span>
                            <v-btn small text color="grey lighten-1" class="v-btn--text-fit">신고</v-btn>
                        </div>
                    </v-col>
                </v-row>
            </div>
            <!-- 관리자 body -->
            <v-card rounded="md" class="pa-12px grey lighten-5">
                <div class="txt txt--xs">
                    {{ value?.reply || "-" }}
                </div>
            </v-card>
        </div>
    </div>
</template>

<script>
import ReviewImagesDialog from "../review-images/dialog/review-images-dialog.vue";

export default {
    components: {
        ReviewImagesDialog,
    },
    props: {
        value: { type: Object, default: () => ({}) }, // review
    },
};
</script>

<style></style>
