<template>
    <ul class="product-intro__coupon-lists">
        <li v-for="coupon in coupons">
            <div class="coupon" style="background-color:#f9b428">
                <div @click="click(coupon)" role="button" class="coupon-button">
                    <span class="title">{{ coupon.name }}</span>
                    <span class="flex">
                        <span class="num"><em>{{ coupon.amount.format() }}</em>{{ coupon.unit }}</span>
                        <span class="round" style="color:#f9b428;stroke:#f9b428">받기
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <path stroke-width="1px" stroke-linecap="butt" stroke-linejoin="miter" fill="none" d="M15.500,10.000 L15.500,14.000 L0.500,14.000 L0.500,10.000 "/>
                                <path d="M7.500,0.000 L8.500,0.000 L8.500,10.000 L7.500,10.000 L7.500,0.000 Z"/>
                                <path stroke-width="1px" stroke-linecap="butt" stroke-linejoin="miter" fill="none" d="M11.500,6.000 L8.500,10.000 "/>
                                <path stroke-width="1px" stroke-linecap="butt" stroke-linejoin="miter" fill="none" d="M4.500,6.000 L7.500,10.000 "/>
                            </svg>
                        </span>
                    </span>
                </div>
                <!-- 쿠폰 삭제 -->
                <span role="button" class="delete"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"> <path fill-rule="evenodd"  fill="rgb(255, 255, 255)" d="M12.157,11.450 L11.450,12.156 L6.500,7.207 L1.550,12.156 L0.843,11.450 L5.793,6.500 L0.843,1.550 L1.550,0.843 L6.500,5.792 L11.450,0.843 L12.157,1.550 L7.207,6.500 L12.157,11.450 Z"/></svg></span>
                <!-- <img src="/images/sample/coupon1.jpg" alt=""> -->
            </div>
        </li>
    </ul>
</template>
<script>
export default{
    props: {
        _product: { type: String, default: null },
        _category: { type: String, default: null }
    },
    data: () => {
        return {
            coupons: []
        };
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            try{
                await this.getCoupons();
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
                else alert(error.message);
            }
        },
        click(coupon){
            this.$emit('click', coupon);
        },
        getCoupons(){
			return new Promise(async (resolve, reject) => {
				try{
                    var headers = {};
					var res = await this.$http.get("/api/v1/shop/coupons", {
						headers,
						params: {
							_product: this._product,
							_category: this._category
						}
					});

					this.coupons = res.data.coupons;

					resolve();
				}
				catch(error){
					reject(error);
				}
			});
		}
    }
}
</script>
