<template>
    <div id="review" class="product-details__content">
        <div class="product-review">
            <!-- head -->
            <div class="pb-20px">
                <v-row align="center" class="row--sm">
                    <v-col cols="auto">
                        <h3 class="tit tit--xxs">상품평 ({{ summary.totalCount }})</h3>
                    </v-col>
                    <!-- <v-col cols="auto">
                        <div class="divider-group">
                            <review-list-sort v-model="sort" />
                        </div>
                    </v-col> -->
                </v-row>
            </div>

            <!-- 총 상품평 -->
            <v-divider />
            <div class="py-20px py-md-32px">
                <review-list-summary v-bind="{ product }" />
            </div>

            <!-- 총 상품평 이미지 -->
            <review-images :_product="$route.params._product" />

            <!-- 개별 상품평 -->
            <div class="border-b">
                <review-list-item v-for="review in reviews" :value="review" :key="review._id" />
            </div>

            <div class="pagination-wrap">
                <v-pagination v-model="page" :length="pageCount" :total-visible="11" @input="search" />
            </div>
        </div>
    </div>
</template>
<script>
import api from "@/api";

import TitWrapSmall from "@/components/client/dumb/tit-wrap-small.vue";
import ReviewImages from "./review-images/review-images.vue";
import ReviewListSort from "./reviews/review-list-sort.vue";
import ReviewListItem from "./reviews/review-list-item.vue";
import ReviewListSummary from "./reviews/review-list-summary.vue";
import PaginationComponent from "../pagination-component.vue";

export default {
    components: {
        TitWrapSmall,
        ReviewImages,
        ReviewListSort,
        ReviewListItem,
        ReviewListSummary,
        PaginationComponent,
    },
    props: {
        product: { type: Object, default: () => ({}) },
    },
    data() {
        return {
            filter: {
                _product: this.$route.params._product || null,
            },

            sort: "byThumb",
            page: 1,
            pageCount: 0,
            limit: 2,

            summary: { totalCount: 0 },
            reviews: [],

            overlay: {
                show: false,
                item: null,
            },
        };
    },
    computed: {
        accessToken() {
            return this.$store.state.accessToken;
        },
    },
    mounted() {
        this.init();
    },
    destroyed() {
        window.removeEventListener("keydown", this.onKeydownEscape);
    },
    watch: {
        sort() {
            this.search();
        },
        "summary.totalCount"(count) {
            this.$emit("setSummaryReviews", count);
        },
    },
    methods: {
        init() {
            this.overlayEscapeEvent = window.addEventListener("keydown", this.onKeydownEscape);
            this.search();
        },
        onKeydownEscape({ key }) {
            if (key == "Escape" && this.overlay.show) this.hideOverlay();
        },

        async search() {
            try {
                const { summary, reviews } = await api.v1.shop.reviews.gets({
                    headers: {
                        skip: (this.page - 1) * this.limit,
                        limit: this.limit,
                        sort: this.sort,
                    },
                    params: this.filter,
                });

                this.summary = summary;
                this.reviews = reviews;
                this.pageCount = Math.ceil(summary.totalCount / this.limit);
            } catch (error) {
                if (error.response) alert(error.response.data.message);
            }
        },

        async thumbup(review) {
            try {
                if (!this.accessToken) throw new Error("로그인 이후 이용해주세요");

                await this.postThumb({ review, type: "thumbup" });
                review.thumbup++;
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
                else alert(error.message.toString());
            }
        },

        async thumbdown(review) {
            try {
                if (!this.accessToken) throw new Error("로그인 이후 이용해주세요");

                await this.postThumb({ review, type: "thumbdown" });
                review.thumbdown--;
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
                else alert(error.message.toString());
            }
        },

        postThumb({ review, type }) {
            return new Promise(async (resolve, reject) => {
                try {
                    var res = await this.$http.post(`/api/v1/shop/reviews/${review._id}/thumbs`, { type });

                    resolve();
                } catch (error) {
                    reject(error);
                }
            });
        },

        showOverlay(item) {
            this.overlay = { show: true, item };
        },

        hideOverlay() {
            this.overlay = { show: false, item: undefined };
        },
    },
};
</script>
