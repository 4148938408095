<template id="modal-template">
    <transition v-if="show" name="modal" appear>
        <div class="modal-container">
            <div class="modal-wrapper">
                <div class="modal">
                    <div class="modal__head">
                        <h1 class="modal__title">할인 가능 쿠폰 받기</h1>
                        <button class="modal__close" type="button" @click="close">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <path d="M0.222,1.636 L1.636,0.221 L15.778,14.363 L14.364,15.778 L0.222,1.636 Z"/>
                                <path d="M14.364,0.221 L15.778,1.636 L1.636,15.778 L0.222,14.363 L14.364,0.221 Z"/>
                            </svg>
                        </button>
                    </div>
                    <div class="modal__body">
                        <div class="download-coupon">
                            <div class="download-coupon__box" style="background-color:#f9b428;">
                                <span class="download-coupon__badge">COUPON</span>
                                <span class="download-coupon__title">{{ coupon.name }}</span>
                                <span class="download-coupon__num"><em>{{ coupon.amount }}</em>{{ coupon.unit }}</span>
                            </div>
                            <p class="download-coupon__pr">
                                <b>쿠폰사용 및 유의사항 안내</b><br>
                                <span v-if="coupon.expiration.enabled">
                                    <b>사용기간 :</b>
                                    <span v-if="coupon.expiration.type == 'dates'"> {{ coupon.expiration.startDate.toDate() }} ~ {{ coupon.expiration.endDate.toDate() }}</span>
                                    <span v-if="coupon.expiration.type == 'days'"> 발급일로부터 {{ coupon.expiration.days.format() }}일 이내</span>
                                </span>
                            </p>
                        </div>
                    </div>
                    <div class="modal__foot">
                        <!-- <p class="modal__foot-caption">* 할인쿠폰은 모든 카테고리에 적용되지 않으며 리본바이옴에서 적용된 카테고리 상품에 한하여 사용 가능합니다.</p> -->
                        <div class="modal__buttons">
                            <button class="button button--primary button--download" type="button" @click="download"><span>쿠폰받기</span></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default{
    data: () => {
        return {
            coupon: null,
            show: false
        };
    },
    methods: {
        open(){
            this.show = true;
            document.querySelector('html').setAttribute('data-modal', 'opened');
        },
        close(){
            this.show = false;
            document.querySelector('html').removeAttribute('data-modal');
        },
        async download(){
            try{
                await this.postCoupon();
                alert("쿠폰이 발급되었습니다");
                this.close();
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
                else alert(error.message);
            }
        },
        postCoupon(){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.post("/api/v1/me/coupons", {
                        _coupon: this.coupon._id
                    });

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        }
    }
}
</script>
