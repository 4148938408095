<template>
    <form ref="form" method="post" action="https://payu.nicepay.co.kr/main.do" accept-charset="euc-kr">
        <input type="hidden" name="MID" :value="merchantID" />
        <input type="hidden" name="MallUserId" :value="buyerUsername" />
        <input type="hidden" name="UserKey" :value="buyerNiceCode" />
        <input type="hidden" name="AuthType" value="2" />
        <input type="hidden" name="BuyerName" :value="buyerName" />
        <input type="hidden" name="BuyerTel" :value="buyerTel" />
        <input type="hidden" name="BuyerEmail" :value="buyerEmail" />
        <input type="hidden" name="LogoImgUrl" :value="logoUrl" />
        <!-- <input type="hidden" name="GuideTitle" value="Title" /> -->
        <!-- <input type="hidden" name="GuideMsg" value="Msg" /> -->
        <input type="hidden" name="Moid" :value="moid" />
        <input type="hidden" name="EdiDate" :value="ediDate" />
        <input type="hidden" name="Amt" :value="amt" />
        <input type="hidden" name="GoodsName" :value="goodsName" />
        <input type="hidden" name="ReturnURL" :value="returnURL" />
        <input type="hidden" name="RcvName" :value="receiverName" />
        <input type="hidden" name="RcvTel" :value="receiverTel" />
        <input type="hidden" name="RcvPostNo" :value="receiverPostcode" />
        <input type="hidden" name="RcvAddr1" :value="receiverAddress1" />
        <input type="hidden" name="RcvAddr2" :value="receiverAddress2" />
        <input type="hidden" name="SignData" :value="hashString" />
        <input type="hidden" name="SkinType" value="green" />
        <input type="hidden" name="CharSet" value="utf-8" />
    </form>
</template>

<script>
import api from "@/api";
import detect from "@/plugins/detect";

export default {
    data: () => ({
        merchantID: null, //        가맹점 아이디
        buyerUsername: null, //     구매자 아이디
        buyerNiceCode: null, //     구매자 간편결제 사용자키 (기존 등록회원)
        buyerName: null, //         구매자명
        buyerTel: null, //          구매자 전화번호
        buyerEmail: null, //        구매자 이메일
        moid: null, //              상품 주문번호
        amt: null, //               상품 금액
        goodsName: null, //         결제상품명
        hashString: null, //        위변조 구분 문자열
        receiverName: null, //      수취인 이름
        receiverTel: null, //       수취인 연락처
        receiverPostcode: null, //  배송지 우편번호
        receiverAddress1: null, //  배송지 주소1
        receiverAddress2: null, //  배송지 주소2
    }),
    computed: {
        logoUrl() {
            return "/images/logo.svg";
        },
        ediDate() {
            return this.$dayjs().format("YYYYMMDDHHmmss");
        },
        returnURL() {
            let { protocol, host } = window.location;
            protocol = protocol.replace(/[^a-z]/g, "");

            return `${protocol}://${host}/api/plugins/nice/pay-u/response`;
        },
    },
    async mounted() {
        let { merchantID } = await api.plugins.nice.pay.config.get();
        this.merchantID = merchantID;
    },
    methods: {
        async submit() {
            const { ediDate, amt } = this;
            this.hashString = (await api.plugins.nice.pay.generate({ ediDate, amt }))?.hashString;

            this.$nextTick((_) => {
                if (detect == "pc") {
                    const popup = window.open("about:blank", "nice-pay-u", `width=420,height=671,status=no,scrollbars=no,resizable=no,left=${Math.round(screen.width / 2) - 210},top=${Math.round(screen.height / 2) - 335}`);

                    if (!popup) {
                        alert("팝업이 차단되어 결제를 진행할 수 없습니다.");
                        return false;
                    }

                    this.$refs["form"].target = "nice-pay-u";
                    this.$refs["form"].submit();
                } else {
                    this.$refs["form"].submit();
                }
            });
        },
    },
};
</script>
