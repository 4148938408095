<template>
    <client-page v-if="ready" class="app--product">
        <page-section class="page-section--first page-section--last">
            <!-- 상품 상세: 이미지,정보 -->
            <section class="product-intro">
                <!-- S: 상품 이미지 -->
                <div class="product-intro__thumb">
                    <!-- 기본 이미지 -->
                    <v-card rounded class="product-intro__thumb-main">
                        <v-img contain :aspect-ratio="1 / 1" :src="`/res/shop/products/${product._id}/${product.images[showImageIndex]}`" />
                    </v-card>
                    <!-- 추가 이미지 -->
                    <div class="pt-16px pt-sm-24px">
                        <v-row class="row--sm row-cols-5">
                            <v-col v-for="(i, index) in 5" :key="`thumb-${index}`" cols="auto">
                                <v-card class="product-intro__thumb-button overflow-hidden" role="button" :aria-pressed="index == showImageIndex" @click="showImageIndex = index">
                                    <v-img cover :aspect-ratio="1 / 1" v-if="product.images[index]" :src="`/res/shop/products/${product._id}/${product.images[index]}`" />
                                </v-card>
                            </v-col>
                        </v-row>
                    </div>
                </div>
                <!-- E: 상품 이미지 -->

                <!-- S: 상품 정보 -->
                <div class="product-intro__info">
                    <!-- 상품 chip -->
                    <div class="pb-16px pb-sm-24px">
                        <v-row class="row--xxs">
                            <v-col v-if="product.newly" cols="auto"><v-chip small color="red lighten-5" text-color="red">신상품</v-chip></v-col>
                            <v-col v-if="product.best" cols="auto"><v-chip small color="light-blue lighten-5" text-color="light-blue">BEST</v-chip></v-col>
                            <!-- <v-col cols="auto"><v-chip small color="purple lighten-5" text-color="purple">당일배송</v-chip></v-col> -->
                        </v-row>
                    </div>

                    <!-- 상품명 -->
                    <div class="pb-16px pb-sm-24px">
                        <!-- <p class="txt txt--xs txt--dark mb-4px">유한상사</p> -->
                        <v-row no-gutters>
                            <v-col>
                                <h3 class="product-intro__name-text">{{ product.name }}</h3>
                            </v-col>
                            <v-col cols="auto">
                                <div class="pl-20px pl-md-40">
                                    <v-row class="row--xs">
                                        <v-col cols="auto">
                                            <like-btn :params="{ _product: product?._id }">
                                                <template #activator="{ active, toggle }">
                                                    <v-btn icon rounded outlined color="grey lighten-3" @click="toggle">
                                                        <span v-if="active" class="material-icons-outlined red--text">favorite</span>
                                                        <span v-else class="material-icons-outlined grey--text">favorite_border</span>
                                                    </v-btn>
                                                </template>
                                            </like-btn>
                                        </v-col>
                                        <v-col cols="auto">
                                            <v-btn icon rounded outlined color="grey lighten-3" @click="share"><span class="material-icons-outlined grey--text">share</span></v-btn>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-col>
                        </v-row>
                        <div class="pt-16px pt-md-24px">
                            <div class="d-flex align-center">
                                <span class="material-icons-outlined primary--text">star</span>
                                <span class="font-size-14 font-weight-medium grey--text">{{ (product?.meta?.reviews?.star__average || 0).toFixed(1) }}</span>
                                <span class="font-size-14 grey--text text--lighten-1 pl-4px">({{ product?.meta?.reviews?.count__total?.format?.() || 0 }})</span>
                            </div>
                        </div>
                    </div>
                    <v-divider />

                    <!-- 상품 가격 -->
                    <div class="py-16px py-sm-24px">
                        <v-row align="center" class="row--xs">
                            <v-col cols="12" md="">
                                <span v-if="product.discountPrice" class="font-size-14 grey--text text--lighten-1 text-decoration-line-through">{{ product.price.format() }}</span>
                                <em v-if="product.soldout || product.stock == 0" class="font-size-14 font-size-md-16 grey--text text--lighten-1">품절</em>
                                <div v-else class="d-flex align-center font-size-18 font-size-lg-28 font-weight-bold">
                                    <span v-if="product.discountPrice" class="primary--text mr-4px mr-md-8px">{{ product.discountRate }}%</span>
                                    <span class="d-flex align-center grey--text text--darken-4">{{ product.salePrice.format() }}<small class="font-size-14 font-weight-regular pl-2px">원</small></span>
                                </div>
                            </v-col>
                            <!-- 기본보너스 영역 -->
                            <!-- <v-col cols="12" md="auto">
                                <div class="txt txt--xs txt--light">기본보너스 <span class="font-weight-meidum primary--text">123p</span></div>
                            </v-col> -->
                        </v-row>
                    </div>
                    <v-divider />

                    <!-- 배송정보 -->
                    <div class="txt txt--xs py-16px py-sm-24px">
                        <tit-wrap-small title="배송정보"></tit-wrap-small>
                        <div>
                            <v-row no-gutters>
                                <v-col cols="12" md="auto">
                                    <div class="txt--light w-md-80px">배송비</div>
                                </v-col>
                                <v-col cols="12" md="">
                                    <div>{{ product.deliveryMessage }}</div>
                                </v-col>
                            </v-row>
                        </div>
                        <div class="pt-4px pt-md-8px">
                            <v-row no-gutters>
                                <v-col cols="12" md="auto">
                                    <div class="txt--light w-md-80px">배송</div>
                                </v-col>
                                <v-col cols="12" md="">
                                    <div>상품* 당일 13시까지 주문 건에 한하여, 당일 출고됩니다. <br class="d-none d-md-block" />택배사 일정에 따라 2~3일 소요될 수 있습니다. (도서산간 제외)</div>
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                    <v-divider />

                    <product-view-benefits v-bind="{ product }" />

                    <!-- S: PC 옵션영역 -->
                    <div class="d-none d-lg-block">
                        <!-- 상품 옵션 -->
                        <div v-if="product?.optionsEnabled || product?.suppliesEnabled || product?.regularDeliveryEnabled" class="txt txt--xs">
                            <!-- 상품 선택옵션 -->
                            <page-section v-if="product?.optionsEnabled" class="page-section--xs pb-0">
                                <template #containerImmersive>
                                    <tit-wrap-small title="선택옵션"></tit-wrap-small>
                                    <shop-product-options v-if="ready" v-model="carts" v-bind="{ product, deliveryType }" />
                                </template>
                            </page-section>
                            <!-- 상품 추가옵션 -->
                            <page-section v-if="product?.suppliesEnabled" class="page-section--xs pb-0">
                                <template #containerImmersive>
                                    <tit-wrap-small title="추가옵션"></tit-wrap-small>
                                    <shop-product-supplies v-if="ready" v-model="carts" v-bind="{ product, deliveryType }" />
                                </template>
                            </page-section>
                            <!-- 정기배송 옵션 -->
                            <page-section v-if="product?.regularDeliveryEnabled" class="page-section--xs pb-0">
                                <template #containerImmersive>
                                    <tit-wrap-small title="배송옵션"></tit-wrap-small>
                                    <shop-product-regular-delivery v-if="ready" v-model="carts" v-bind="{ product, deliveryType }" @deliveryType="setDeliveryType" />
                                </template>
                            </page-section>
                        </div>

                        <!-- 선택한 옵션 그룹 -->
                        <shop-product-carts v-model="carts" />

                        <!-- 총 상품 금액 -->
                        <page-section class="page-section--xs pb-0">
                            <template #containerImmersive>
                                <v-row align="center">
                                    <v-col cols="auto">
                                        <div class="txt txt--xs">총 상품 금액</div>
                                    </v-col>
                                    <v-spacer />
                                    <v-col cols="auto">
                                        <span class="d-flex align-center line-height-1 primary--text">
                                            <strong class="font-size-20 font-size-lg-36">{{ totalPrice.format() }}</strong>
                                            <span class="pl-2px">원</span>
                                        </span>
                                    </v-col>
                                </v-row>
                            </template>
                        </page-section>

                        <!-- 버튼 영역 -->
                        <div class="btn-wrap">
                            <v-row class="row--sm">
                                <v-col cols="12" sm="6">
                                    <v-btn block outlined color="primary" class="v-size--xx-large" v-bind="{ loading }" @click="addToCart">장바구니</v-btn>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-btn block color="primary" class="v-size--xx-large" v-bind="{ loading }" @click="buyNow">{{ deliveryType === "single" ? "바로구매" : "정기배송 신청하기" }}</v-btn>
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                    <!-- E: PC 옵션영역 -->

                    <!-- <div class="btn-wrap">
                        <naver-pay disabled button-key="E8FAC592-0582-4E64-B0A7-410385FFD5AA" :carts="carts" :_product="this.product._id"></naver-pay>
                    </div> -->

                    <!-- S: 모바일하단 고정영역 -->
                    <v-bottom-navigation fixed height="auto" class="product-bottom-fixed">
                        <div class="w-100 pa-12px">
                            <v-row class="row--sm">
                                <v-col cols="12">
                                    <v-btn block color="primary" class="v-size--xx-large" @click="bottomNav = true"><span class="white--text">구매하기</span></v-btn>
                                </v-col>
                            </v-row>
                        </div>
                    </v-bottom-navigation>
                    <v-bottom-navigation :input-value="bottomNav" fixed height="auto" class="product-bottom-fixed-view">
                        <v-btn block color="transparent" class="rounded-0" @click="bottomNav = false">
                            <v-icon size="24" color="grey">mdi-chevron-down</v-icon>
                        </v-btn>
                        <div class="w-100 pa-12px">
                            <div class="overflow-x-hidden overflow-y-auto" style="max-height: 50vh">
                                <!-- 상품 옵션 -->
                                <div v-if="product?.optionsEnabled || product?.suppliesEnabled || product?.regularDeliveryEnabled" class="txt txt--xs">
                                    <!-- 상품 선택옵션 -->
                                    <page-section v-if="product?.optionsEnabled" class="page-section--xs py-0">
                                        <template #containerImmersive>
                                            <tit-wrap-small title="선택옵션"></tit-wrap-small>
                                            <shop-product-options v-if="ready" v-model="carts" v-bind="{ product }" />
                                        </template>
                                    </page-section>
                                    <!-- 상품 추가옵션 -->
                                    <page-section v-if="product?.suppliesEnabled" class="page-section--xs pb-0">
                                        <template #containerImmersive>
                                            <tit-wrap-small title="추가옵션"></tit-wrap-small>
                                            <shop-product-supplies v-if="ready" v-model="carts" v-bind="{ product }" />
                                        </template>
                                    </page-section>
                                    <!-- 정기배송 옵션 -->
                                    <page-section v-if="product?.regularDeliveryEnabled" class="page-section--xs pb-0">
                                        <template #containerImmersive>
                                            <tit-wrap-small title="배송옵션"></tit-wrap-small>
                                            <shop-product-regular-delivery v-if="ready" v-model="carts" v-bind="{ product }" />
                                        </template>
                                    </page-section>
                                </div>

                                <!-- 선택한 옵션 그룹 -->
                                <shop-product-carts v-model="carts" />
                            </div>

                            <!-- 총 상품 금액 -->
                            <page-section class="page-section--xs pb-0">
                                <template #containerImmersive>
                                    <v-row align="center">
                                        <v-col cols="auto">
                                            <div class="txt txt--xs">총 상품 금액</div>
                                        </v-col>
                                        <v-spacer />
                                        <v-col cols="auto">
                                            <span class="d-flex align-center line-height-1 primary--text">
                                                <strong class="font-size-20 font-size-lg-36">{{ totalPrice.format() }}</strong>
                                                <span class="pl-2px">원</span>
                                            </span>
                                        </v-col>
                                    </v-row>
                                </template>
                            </page-section>

                            <!-- 버튼 영역 -->
                            <div class="btn-wrap">
                                <v-row class="row--sm">
                                    <v-col cols="6">
                                        <v-btn block outlined color="primary" class="v-size--xx-large primary--text" v-bind="{ loading }" @click="addToCart">장바구니</v-btn>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-btn block color="primary" class="v-size--xx-large" v-bind="{ loading }" @click="buyNow"><span class="white--text">바로구매</span></v-btn>
                                    </v-col>
                                </v-row>
                            </div>
                        </div>
                    </v-bottom-navigation>
                    <!-- E: 모바일하단 고정영역 -->
                </div>

                <!-- 할인쿠폰 -->
                <!-- <div class="product-intro__coupon">
                    <span class="product-intro__coupon-title"><b>이 상품!</b> <br class="pc" />할인가능쿠폰</span>
                    <shop-product-coupons :_product="product._id" :_category="product._category" @click="openModalCoupon"></shop-product-coupons>
                </div> -->
                <!-- E: 상품 정보 -->
            </section>
            <!-- // 상품 상세: 이미지,정보 -->

            <!-- 상품 상세: 콘텐츠 -->
            <section class="product-details">
                <!-- S: 콘텐츠 탭 -->
                <div v-scroll="onScrollFixed" class="scroll-fixed-wrap">
                    <div class="scroll-fixed">
                        <v-container>
                            <!-- S: PC상단 고정영역 -->
                            <div class="product-top-fixed">
                                <!-- S: PC상단 고정영역(항상보임) -->
                                <v-row align="center">
                                    <v-col cols="auto">
                                        <!-- 기본 이미지 -->
                                        <v-card rounded>
                                            <v-img contain width="78" :aspect-ratio="1 / 1" :src="`/res/shop/products/${product._id}/${product.images[showImageIndex]}`" />
                                        </v-card>
                                    </v-col>
                                    <v-col cols="">
                                        <div class="txt txt--sm txt--dark font-weight-medium mb-12px">{{ product.name }}</div>
                                        <div class="d-flex align-center font-size-20 font-size-md-28">
                                            <strong v-if="product.discountPrice" class="primary--text mr-8px">{{ product.discountRate }}%</strong>
                                            <strong v-if="product.salePrice" class="d-inline-flex align-center">{{ product.salePrice.format() }}<small class="font-size-14 font-weight-regular pl-2px">원</small></strong>
                                            <span v-if="product.discountPrice" class="font-size-14 text-decoration-line-through grey--text text--lighten-1 ml-8px">{{ product.price.format() }}원</span>
                                        </div>
                                    </v-col>
                                    <v-col cols="auto">
                                        <v-btn v-if="productTopFixedBtn" color="primary" class="v-size--xx-large min-w-200px" @click="((productTopFixedView = true) || true) && (productTopFixedBtn = false)">바로 구매</v-btn>
                                        <v-btn v-else x-small icon class="transparent" @click="((productTopFixedView = false) || true) && (productTopFixedBtn = true)"><v-icon size="24" color="grey">mdi-close</v-icon></v-btn>
                                    </v-col>
                                </v-row>
                                <!-- E: PC상단 고정영역(항상보임) -->

                                <!-- S: PC상단 고정영역(바로구매버튼 클릭시 보임) -->
                                <v-divider v-show="productTopFixedView" class="my-24px" />
                                <div v-show="productTopFixedView" class="product-top-fixed-view">
                                    <div class="overflow-hidden">
                                        <v-row>
                                            <v-col cols="4">
                                                <!-- 배송정보 -->
                                                <div class="txt txt--xs">
                                                    <tit-wrap-small title="배송정보"></tit-wrap-small>
                                                    <div>
                                                        <v-row no-gutters>
                                                            <v-col cols="12" md="auto">
                                                                <div class="txt--light w-md-80px">배송비</div>
                                                            </v-col>
                                                            <v-col cols="12" md="">
                                                                <div>{{ product.deliveryMessage }}</div>
                                                            </v-col>
                                                        </v-row>
                                                    </div>
                                                    <div class="pt-4px pt-md-8px">
                                                        <v-row no-gutters>
                                                            <v-col cols="12" md="auto">
                                                                <div class="txt--light w-md-80px">배송</div>
                                                            </v-col>
                                                            <v-col cols="12" md="">
                                                                <div>상품* 당일 13시까지 주문 건에 한하여, 당일 출고됩니다. <br class="d-none d-md-block" />택배사 일정에 따라 2~3일 소요될 수 있습니다. (도서산간 제외)</div>
                                                            </v-col>
                                                        </v-row>
                                                    </div>
                                                </div>
                                            </v-col>
                                            <v-col v-if="product?.optionsEnabled || product?.suppliesEnabled || product?.regularDeliveryEnabled" cols="4">
                                                <!-- 상품 선택옵션 -->
                                                <page-section v-if="product?.optionsEnabled" class="page-section--xs py-0">
                                                    <template #containerImmersive>
                                                        <tit-wrap-small title="선택옵션"></tit-wrap-small>
                                                        <shop-product-options v-if="ready" v-model="carts" v-bind="{ product }" />
                                                    </template>
                                                </page-section>
                                                <!-- 상품 추가옵션 -->
                                                <page-section v-if="product?.suppliesEnabled" class="page-section--xs pb-0">
                                                    <template #containerImmersive>
                                                        <tit-wrap-small title="추가옵션"></tit-wrap-small>
                                                        <shop-product-supplies v-if="ready" v-model="carts" v-bind="{ product }" />
                                                    </template>
                                                </page-section>
                                                <!-- 정기배송 옵션 -->
                                                <page-section v-if="product?.regularDeliveryEnabled" class="page-section--xs pb-0">
                                                    <template #containerImmersive>
                                                        <tit-wrap-small title="배송옵션"></tit-wrap-small>
                                                        <shop-product-regular-delivery v-if="ready" v-model="carts" v-bind="{ product }" />
                                                    </template>
                                                </page-section>
                                            </v-col>
                                            <v-col cols="4">
                                                <!-- 선택한 옵션 그룹 -->
                                                <shop-product-carts v-model="carts" class="pt-0" />

                                                <!-- 총 상품 금액 -->
                                                <page-section class="page-section--xs pb-0">
                                                    <template #containerImmersive>
                                                        <v-row align="center">
                                                            <v-col cols="auto">
                                                                <div class="txt txt--xs">총 상품 금액</div>
                                                            </v-col>
                                                            <v-spacer />
                                                            <v-col cols="auto">
                                                                <span class="d-flex align-center line-height-1 primary--text">
                                                                    <strong class="font-size-20 font-size-lg-36">{{ totalPrice.format() }}</strong>
                                                                    <span class="pl-2px">원</span>
                                                                </span>
                                                            </v-col>
                                                        </v-row>
                                                    </template>
                                                </page-section>

                                                <!-- 버튼 영역 -->
                                                <div class="btn-wrap">
                                                    <v-row class="row--sm">
                                                        <v-col cols="12" sm="6">
                                                            <v-btn block outlined color="primary" class="v-size--xx-large" v-bind="{ loading }" @click="addToCart">장바구니</v-btn>
                                                        </v-col>
                                                        <v-col cols="12" sm="6">
                                                            <v-btn block color="primary" class="v-size--xx-large" v-bind="{ loading }" @click="buyNow">바로구매</v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </div>
                                <!-- E: PC상단 고정영역(바로구매버튼 클릭시 보임) -->
                            </div>
                            <!-- E: PC상단 고정영역 -->

                            <scrollactive :offset="$vuetify.breakpoint.lgAndUp ? 186 : 100" :scrollOffset="$vuetify.breakpoint.lgAndUp ? 186 : 100" :highlightFirstItem="true" active-class="v-tab--active" class="v-tabs">
                                <router-link to="#review" class="scrollactive-item v-tab"
                                    >상품평<span class="d-none d-lg-inline-block">({{ summary?.reviews?.format?.() || 0 }})</span></router-link
                                >
                                <router-link to="#detail" class="scrollactive-item v-tab">상품상세</router-link>
                                <router-link to="#qna" class="scrollactive-item v-tab"
                                    >상품문의<span class="d-none d-lg-inline-block">({{ summary?.inquires?.format?.() || 0 }})</span></router-link
                                >
                                <router-link to="#delivery" class="scrollactive-item v-tab">배송/교환/반품 정보</router-link>
                            </scrollactive>
                        </v-container>
                    </div>
                </div>
                <!-- E: 콘텐츠 탭 -->

                <!-- S: 상품평 -->
                <shop-product-reviews v-bind="{ product }" @setSummaryReviews="(count) => (summary.reviews = count)" />
                <!-- E: 상품평 -->

                <!-- S: 상품상세 -->
                <div id="detail" class="product-details__content">
                    <section class="product-sub" v-html="product.content"></section>
                </div>
                <!-- E: 상품상세 -->

                <!-- S: 상품문의 -->
                <shop-product-inquires :product="product" @setSummaryInquires="(count) => (summary.inquires = count)" />
                <!-- E: 상품문의 -->

                <!-- S: 배송/교환/반품정보 -->
                <div id="delivery" class="product-details__content">
                    <div class="pb-20px">
                        <v-row align="center" class="row--sm">
                            <v-col cols="auto">
                                <h3 class="tit tit--xxs">배송/교환/반품 정보</h3>
                            </v-col>
                        </v-row>
                    </div>

                    <v-row>
                        <v-col cols="12" sm="6" md="4">
                            <div class="txt txt--xs">
                                <tit-wrap-small title="반품"></tit-wrap-small>
                                <div>
                                    <v-row no-gutters>
                                        <v-col cols="12" md="auto">
                                            <div class="txt--light w-md-80px">반품신청​</div>
                                        </v-col>
                                        <v-col cols="12" md="">
                                            <div>구매자 책임사유시 반품/배송비 결제​</div>
                                        </v-col>
                                    </v-row>
                                </div>
                                <div class="pt-4px pt-md-8px">
                                    <v-row no-gutters>
                                        <v-col cols="12" md="auto">
                                            <div class="txt--light w-md-80px">발송하기</div>
                                        </v-col>
                                        <v-col cols="12" md="">
                                            <div>1~2일 이내 택배기사 방문​</div>
                                        </v-col>
                                    </v-row>
                                </div>
                                <div class="pt-4px pt-md-8px">
                                    <v-row no-gutters>
                                        <v-col cols="12" md="auto">
                                            <div class="txt--light w-md-80px">반품접수완료​</div>
                                        </v-col>
                                        <v-col cols="12" md="">
                                            <div>리본바이옴에 반품상품 전달​</div>
                                        </v-col>
                                    </v-row>
                                </div>
                                <div class="pt-4px pt-md-8px">
                                    <v-row no-gutters>
                                        <v-col cols="12" md="auto">
                                            <div class="txt--light w-md-80px">반품완료</div>
                                        </v-col>
                                        <v-col cols="12" md="">
                                            <div>확인 후 환불처리​</div>
                                        </v-col>
                                    </v-row>
                                </div>
                            </div>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <div class="txt txt--xs">
                                <tit-wrap-small title="교환"></tit-wrap-small>
                                <div>
                                    <v-row no-gutters>
                                        <v-col cols="12" md="auto">
                                            <div class="txt--light w-md-80px">교환신청</div>
                                        </v-col>
                                        <v-col cols="12" md="">
                                            <div>구매자 책임사유시 반품/배송비 결제​</div>
                                        </v-col>
                                    </v-row>
                                </div>
                                <div class="pt-4px pt-md-8px">
                                    <v-row no-gutters>
                                        <v-col cols="12" md="auto">
                                            <div class="txt--light w-md-80px">발송하기</div>
                                        </v-col>
                                        <v-col cols="12" md="">
                                            <div>1~2일 이내 택배기사 방문​</div>
                                        </v-col>
                                    </v-row>
                                </div>
                                <div class="pt-4px pt-md-8px">
                                    <v-row no-gutters>
                                        <v-col cols="12" md="auto">
                                            <div class="txt--light w-md-80px">교환접수</div>
                                        </v-col>
                                        <v-col cols="12" md="">
                                            <div>리본바이옴에 교환상품 전달​</div>
                                        </v-col>
                                    </v-row>
                                </div>
                                <div class="pt-4px pt-md-8px">
                                    <v-row no-gutters>
                                        <v-col cols="12" md="auto">
                                            <div class="txt--light w-md-80px">상품재배송​</div>
                                        </v-col>
                                        <v-col cols="12" md="">
                                            <div>확인 후 교환​</div>
                                        </v-col>
                                    </v-row>
                                </div>
                                <div class="pt-4px pt-md-8px">
                                    <v-row no-gutters>
                                        <v-col cols="12" md="auto">
                                            <div class="txt--light w-md-80px">교환완료</div>
                                        </v-col>
                                        <v-col cols="12" md="">
                                            <div>완료</div>
                                        </v-col>
                                    </v-row>
                                </div>
                            </div>
                        </v-col>
                        <v-col cols="12" md="4">
                            <div class="txt txt--xs">
                                <tit-wrap-small title="주의사항"></tit-wrap-small>
                                <div>
                                    <v-row class="row--xs">
                                        <v-col cols="auto">-</v-col>
                                        <v-col cols=""> 미성년자가 물품을 구매하는 경우, 법정대리인이 동의하지 않으면 미성년자 본인 또는 법정대리인이 구매를 취소할 수 있습니다.​​ </v-col>
                                    </v-row>
                                </div>
                                <div class="pt-4px pt-md-8px">
                                    <v-row class="row--xs">
                                        <v-col cols="auto">-</v-col>
                                        <v-col cols=""> 안전검사 대상 공산품’을 구매하시는 경우 품질경영 및 공산품 안전관리법에 따른 안전검사 표시가 있는 제품인지 확인하시기 바랍니다.​ </v-col>
                                    </v-row>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </div>
                <!-- E: 배송/교환/반품정보 -->

                <!-- 옵션 요약 사이드바 -->
                <div v-show="false" class="product-details__sidebar">
                    <div class="product-sidebar" data-sidebar-fixed="off">
                        <div class="product-sidebar__contents">
                            <!-- 모바일 하단 옵션바 닫기 버튼 -->
                            <button class="product-sidebar__close" aria-pressed="false"><span class="blind">옵션 닫기</span><i class="arrow"></i></button>
                            <div class="product-sidebar__top">
                                <span class="product-sidebar__title">{{ product.name }}</span>

                                <!-- 상품 옵션 그룹 -->
                                <shop-product-options v-if="ready" v-model="carts" v-bind="{ product }" sidebar />

                                <!-- 추가 상품 그룹 -->
                                <shop-product-supplies v-if="ready" v-model="carts" v-bind="{ product }" sidebar />

                                <!-- 선택한 옵션 박스 그룹 -->
                                <div class="product-sidebar__option-box-group">
                                    <!-- 옵션 박스 -->
                                    <div v-for="(cart, index) in carts" class="product-option-box" :key="index">
                                        <button class="product-option-box__delete" type="button" @click="carts.splice(index, 1)"><span class="blind">삭제</span></button>
                                        <span class="product-option-box__title">{{ cart.name }} <span v-if="cart.stock == 0">(품절)</span></span>
                                        <div class="product-option-box__flex">
                                            <!-- 옵션 수량 -->
                                            <div class="spinner">
                                                <button class="spinner__minus" type="button" @click="decAmount(cart)"></button>
                                                <input v-model="cart.amount" class="spinner__num" type="number" @change="changeAmount(cart)" :disalbed="!cart.stock" />
                                                <button class="spinner__plus" type="button" @click="incAmount(cart)"></button>
                                            </div>
                                            <div class="product-option-box__price">
                                                <span class="price">
                                                    <em class="num">{{ (cart.salePrice * cart.amount).format() }}원</em>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="product-sidebar__bottom">
                                <div class="product-sidebar__total">
                                    <span class="price">
                                        총 <em class="num">{{ totalPrice.format() }}</em> 원
                                    </span>
                                </div>
                                <div class="product-sidebar__buttons">
                                    <a class="button button--border-primary font-nanum-m" v-bind="{ loading }" @click="addToCart"><span>장바구니</span></a>
                                    <a class="button button--primary font-nanum-m" v-bind="{ loading }" @click="buyNow"><span>바로구매</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <!-- 장바구니 모달 -->
            <modal-cart ref="modal-cart"></modal-cart>
            <!-- 쿠폰받기 모달 -->
            <!-- <modal-coupon ref="modal-coupon"></modal-coupon> -->
        </page-section>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import TitWrapSmall from "@/components/client/dumb/tit-wrap-small.vue";

import LikeBtn from "@/components/client/like/like-btn.vue";
import NaverPay from "../../../components/plugins/naver/naver-pay.vue";
import ModalCart from "../../../components/client/shop/modal-cart.vue";
import ModalCoupon from "../../../components/client/shop/modal-coupon.vue";
import ShopProductCarts from "@/components/client/shop/shop-product-carts.vue";
import ShopProductCoupons from "../../../components/client/shop/shop-product-coupons.vue";
import ShopProductOptions from "../../../components/client/shop/shop-product-options.vue";
import ShopProductReviews from "../../../components/client/shop/shop-product-reviews.vue";
import ProductViewBenefits from "@/components/client/shop/products/product-view-benefits.vue";
import ShopProductSupplies from "../../../components/client/shop/shop-product-supplies.vue";
import ShopProductInquires from "../../../components/client/shop/shop-product-inquires.vue";
import ShopProductRegularDelivery from "../../../components/client/shop/shop-product-regular-delivery.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        TitWrapSmall,

        LikeBtn,
        NaverPay,
        ModalCart,
        ModalCoupon,
        ShopProductCarts,
        ShopProductCoupons,
        ShopProductOptions,
        ShopProductReviews,
        ProductViewBenefits,
        ShopProductSupplies,
        ShopProductInquires,
        ShopProductRegularDelivery,
    },
    data: () => ({
        seo: {},

        ready: false,
        product: null,
        loading: false,

        carts: [],
        showImageIndex: 0,

        coupons: [],

        summary: {
            reviews: 0,
            inquires: 0,
        },

        deliveryType: "single",

        bottomNav: false,
        productTopFixedBtn: true,
        productTopFixedView: false,
    }),
    metaInfo() {
        return Object.assign({}, this.seo);
    },
    computed: {
        accessToken() {
            return this.$store.state.accessToken;
        },
        payload() {
            return this.$store.state.payload;
        },
        totalPrice() {
            return this.carts.reduce((totalPrice, cart) => {
                return totalPrice + cart.salePrice * cart.amount;
            }, 0);
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        setDeliveryType(deliveryType) {
            this.deliveryType = deliveryType;
        },
        openModalCoupon(coupon) {
            this.$refs["modal-coupon"].coupon = coupon;
            this.$refs["modal-coupon"].open();
        },

        async init() {
            try {
                await this.getProduct();

                var product = this.product;
                if (!product.optionsEnabled) {
                    this.$set(this.carts, 0, {
                        _product: product._id,
                        product,
                        name: "본품",
                        price: product.price,
                        salePrice: product.salePrice,
                        discountPrice: product.discountPrice,
                        stock: product.stock,
                        deliveryType: "single",
                        amount: 1,
                    });
                }
                this.ready = true;
            } catch (error) {
                console.error(error);
            } finally {
                this.$nextTick(this.onScrollFixed);
            }
        },

        onScrollFixed() {
            let scrollPosition = 0,
                mobileWidth = 1024;

            let scrollFixedWrap = document.querySelector(".scroll-fixed-wrap"),
                scrollFixed = document.querySelector(".scroll-fixed"),
                absoluteTop = 0,
                headerHeight = 50,
                tabHeight = 50;
            if (window.innerWidth > mobileWidth) {
                headerHeight = 186;
                tabHeight = 60;
            }

            scrollPosition = window.scrollY || window.pageYOffset;
            if (window.innerWidth > mobileWidth) {
                // PC
                absoluteTop = window.pageYOffset + scrollFixedWrap.getBoundingClientRect().top - headerHeight + tabHeight;
            } else {
                // Mobile
                absoluteTop = window.pageYOffset + scrollFixedWrap.getBoundingClientRect().top - tabHeight;
            }

            if (scrollPosition < absoluteTop) {
                scrollFixed.classList.remove("active");
            } else {
                scrollFixed.classList.add("active");
            }
        },

        ////////////////////////////////////////////////////////////////////////
        // 장바구니 담기
        ////////////////////////////////////////////////////////////////////////
        async addToCart() {
            if (this.loading) return;
            else this.loading = true;

            try {
                if (!this.carts.length) throw new Error("구매하실 상품을 선택해주세요");

                var soldout = this.carts.find((cart) => cart.stock == 0);
                if (soldout) throw new Error("품절된 상품입니다");

                const carts = await this.postCarts(this.carts);
                this.$store.dispatch("addToCart", carts);

                this.$refs["modal-cart"].open();
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
                else alert(error.message.toString());
            } finally {
                this.loading = false;
            }
        },

        ////////////////////////////////////////////////////////////////////////
        // 바로구매
        ////////////////////////////////////////////////////////////////////////
        async buyNow() {
            if (this.loading) return;
            else this.loading = true;

            try {
                if (!this.carts.length) {
                    let toggle = document.querySelector(".product-sidebar__close");
                    document.querySelector(".product-details__sidebar").setAttribute("data-sidebar-mobile", "opened");
                    toggle.setAttribute("aria-pressed", "true");
                    throw new Error("구매하실 상품을 선택해주세요");
                }

                var soldout = this.carts.find((cart) => cart.stock == 0);
                if (soldout) throw new Error("품절된 상품입니다");

                const carts = await this.postCarts(this.carts, "buy");
                this.$store.dispatch("addToCart", carts);

                const _carts = carts.map(({ _id }) => _id);
                this.$store.dispatch("pickup", _carts);
                if (carts[0].deliveryType === "single") {
                    this.$router.push({ path: "/shop/order?deliveryType=single" });
                } else {
                    this.$router.push({ path: "/mypage/cart/regular" });
                }
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
                else alert(error.message.toString());
            } finally {
                this.loading = false;
            }
        },

        getProduct() {
            return new Promise(async (resolve, reject) => {
                try {
                    var res = await this.$http.get(`/api/v1/shop/products/${this.$route.params._product}`);

                    this.product = res.data.product;
                    this.seo = this.product.seoEnabled && this.product.seo ? this.product.seo : {};

                    resolve();
                } catch (error) {
                    reject(error);
                }
            });
        },

        postCarts(carts, mode) {
            return new Promise(async (resolve, reject) => {
                try {
                    var res = await this.$http.post("/api/v1/me/carts", { carts, mode });

                    resolve(res.data.carts);
                } catch (error) {
                    reject(error);
                }
            });
        },

        share() {
            navigator.share({
                text: this.product.name,
                url: window.location.href,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.scroll-fixed-wrap {
    --tab-height: 50px;
    height: var(--tab-height);
    transition: 0.25s ease-out;
    .v-tabs {
        display: flex;
        height: var(--tab-height);
        .v-tab {
            width: 25% !important;
            height: 100%;
        }
    }
    .v-tab {
        &--active {
            color: var(--v-primary-base);
        }
    }
}
.scroll-fixed {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 1px;
        background-color: var(--border-color);
    }
    &.active {
        position: fixed;
        top: 50px;
        left: 0;
        width: 100%;
        z-index: 10;
    }
    &:not(.active) {
        .container {
            max-width: var(--container);
            padding: 0;
        }
    }
}
.scrollactive-nav {
    position: relative;
    margin-top: auto;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
    .scroll-fixed-wrap {
        --tab-height: 60px;
    }
    .scroll-fixed {
        &.active {
            top: 0;
            min-height: 186px;
        }
    }
}
@media (min-width: 1200px) {
}
@media (max-width: 576px) {
    .scroll-fixed {
        .container {
            padding: 0;
        }
    }
}
</style>
