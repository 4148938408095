var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-dialog', {
    attrs: {
      "name": "modal",
      "title": "문의하기"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }, {
      key: "actions",
      fn: function () {
        return [_c('v-row', {
          staticClass: "row--sm",
          attrs: {
            "justify": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('v-btn', {
          staticClass: "v-size--xx-large",
          attrs: {
            "block": "",
            "color": "primary"
          },
          on: {
            "click": _vm.submit
          }
        }, [_vm._v("문의하기")])], 1)], 1)];
      },
      proxy: true
    }], null, true),
    model: {
      value: _vm.show,
      callback: function ($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_c('tit-wrap-small', {
    attrs: {
      "title": "문의 제목"
    }
  }), _c('v-text-field', {
    attrs: {
      "dense": "",
      "outlined": "",
      "hide-details": "",
      "placeholder": "문의 제목을 입력해주세요."
    },
    model: {
      value: _vm.inquire.subject,
      callback: function ($$v) {
        _vm.$set(_vm.inquire, "subject", $$v);
      },
      expression: "inquire.subject"
    }
  }), _c('page-section', {
    staticClass: "page-section--xs pb-0",
    scopedSlots: _vm._u([{
      key: "containerImmersive",
      fn: function () {
        return [_c('tit-wrap-small', {
          attrs: {
            "title": "문의 내용"
          }
        }), _c('v-textarea', {
          attrs: {
            "dense": "",
            "outlined": "",
            "hide-details": "",
            "placeholder": "문의 내용을 입력해주세요."
          },
          model: {
            value: _vm.inquire.content,
            callback: function ($$v) {
              _vm.$set(_vm.inquire, "content", $$v);
            },
            expression: "inquire.content"
          }
        }), _c('div', {
          staticClass: "pt-8px"
        }, [_c('v-checkbox', {
          attrs: {
            "value": true,
            "hide-details": "",
            "label": "비공개"
          },
          model: {
            value: _vm.inquire.secret,
            callback: function ($$v) {
              _vm.$set(_vm.inquire, "secret", $$v);
            },
            expression: "inquire.secret"
          }
        })], 1)];
      },
      proxy: true
    }])
  }), _c('page-section', {
    staticClass: "page-section--xs pb-0",
    scopedSlots: _vm._u([{
      key: "containerImmersive",
      fn: function () {
        return [_c('tit-wrap-small', {
          attrs: {
            "title": "이미지 등록"
          }
        }), _c('form-files', {
          model: {
            value: _vm.inquire.images,
            callback: function ($$v) {
              _vm.$set(_vm.inquire, "images", $$v);
            },
            expression: "inquire.images"
          }
        })];
      },
      proxy: true
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }