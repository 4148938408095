var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('vertical-form-table', _vm._b({
    on: {
      "input": _vm.emit
    },
    scopedSlots: _vm._u([{
      key: "배송지선택",
      fn: function () {
        var _vm$defaultReceiver;
        return [_c('v-radio-group', {
          attrs: {
            "row": "",
            "hide-details": ""
          },
          model: {
            value: _vm.mode,
            callback: function ($$v) {
              _vm.mode = $$v;
            },
            expression: "mode"
          }
        }, [_c('v-radio', {
          attrs: {
            "value": "default",
            "label": "기본배송지",
            "disabled": !((_vm$defaultReceiver = _vm.defaultReceiver) !== null && _vm$defaultReceiver !== void 0 && _vm$defaultReceiver._id)
          }
        }), _c('v-radio', {
          attrs: {
            "value": "form",
            "label": "신규배송지"
          }
        }), _c('destination-select-dialog', {
          on: {
            "input": _vm.emit
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref) {
              var attrs = _ref.attrs,
                on = _ref.on;
              return [_c('v-btn', _vm._g(_vm._b({
                staticClass: "v-size--xx-small",
                attrs: {
                  "outlined": "",
                  "color": "grey"
                }
              }, 'v-btn', attrs, false), on), [_vm._v("배송지 목록")])];
            }
          }]),
          model: {
            value: _vm.form.receiver,
            callback: function ($$v) {
              _vm.$set(_vm.form, "receiver", $$v);
            },
            expression: "form.receiver"
          }
        })], 1)];
      },
      proxy: true
    }, _vm.mode != 'default' ? {
      key: "주문자동일",
      fn: function () {
        return [_c('v-checkbox', {
          attrs: {
            "hide-details": "",
            "label": "주문자 정보와 동일하게 적용"
          },
          on: {
            "change": _vm.setSameAsUser
          },
          model: {
            value: _vm.sameAsUser,
            callback: function ($$v) {
              _vm.sameAsUser = $$v;
            },
            expression: "sameAsUser"
          }
        })];
      },
      proxy: true
    } : null, _vm.mode != 'default' ? {
      key: "배송지명",
      fn: function () {
        return [_c('v-text-field', _vm._b({
          attrs: {
            "placeholder": "배송지명을 입력하세요."
          },
          on: {
            "input": _vm.emit
          },
          model: {
            value: _vm.form.receiver.title,
            callback: function ($$v) {
              _vm.$set(_vm.form.receiver, "title", $$v);
            },
            expression: "form.receiver.title"
          }
        }, 'v-text-field', _vm.attrs_input, false)), _c('div', {
          staticClass: "pt-8px"
        }, [_c('v-checkbox', {
          attrs: {
            "hide-details": "",
            "label": "기본배송지로 선택"
          },
          on: {
            "change": _vm.emit
          },
          model: {
            value: _vm.form.receiver.isDefault,
            callback: function ($$v) {
              _vm.$set(_vm.form.receiver, "isDefault", $$v);
            },
            expression: "form.receiver.isDefault"
          }
        })], 1)];
      },
      proxy: true
    } : null], null, true),
    model: {
      value: _vm.form,
      callback: function ($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  }, 'vertical-form-table', {
    mode: _vm.mode,
    items: _vm.items
  }, false));

}
var staticRenderFns = []

export { render, staticRenderFns }