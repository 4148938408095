<template>
    <v-row align="center">
        <v-col cols="12" md="3">
            <div class="text-center border-md-r">
                <div class="txt txt--sm txt--dark font-weight-medium">구매만족도</div>
                <div class="font-size-20 font-size-md-36 font-weight-bold">{{ (meta?.star__average || 0)?.toFixed(1) }}</div>
                <v-rating half-increments readonly color="primary" background-color="primary" :value="meta.star__average" />
                <div class="txt txt--xs txt--light">({{ meta.count__total }}건)</div>
            </div>
        </v-col>
        <v-col cols="12" md="9">
            <div class="pl-md-36px pr-md-80px font-size-14 grey--text text--lighten-1">
                <template v-for="star in [5, 4, 3, 2, 1]">
                    <v-row align="center" class="row--sm" :key="star">
                        <v-col cols="auto">
                            <div style="width:36px">{{ star }}점</div>
                        </v-col>
                        <v-col cols="">
                            <v-progress-linear rounded :color="meta['count__star' + star] && meta['count__star' + star] == star__highest ? 'red' : 'grey lighten-1'" height="10" :value="stars__percentage[star]" />
                        </v-col>
                        <v-col cols="auto">
                            <div class="text-right" :class="{ 'red--text': meta['count__' + star] && meta['count__' + star] == star__highest }" style="width:48px">{{ stars__percentage[star].toFixed(0) }}%</div>
                        </v-col>
                    </v-row>
                </template>
            </div>
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: {
        product: { type: Object, default: () => ({}) },
    },
    computed: {
        meta() {
            return this.product?.meta?.reviews;
        },
        star__highest() {
            return [1, 2, 3, 4, 5].reduce((highest, star) => (highest < this.meta["count__star" + star] ? this.meta["count__star" + star] : highest), 0);
        },
        stars__percentage() {
            return [1, 2, 3, 4, 5].reduce((percentage, star) => ({ ...percentage, [star]: ((this.meta["count__star" + star] || 0) / this.meta.count__total) * 100 || 0 }), {});
        },
    },
};
</script>

<style></style>
