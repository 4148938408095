<template>
    <u-dialog v-model="shows" title="간편비밀번호확인" persistent width="400">
        <template v-if="type === 'check'">
            <div class="txt txt--xs txt--dark font-weight-medium text-center pt-12px pt-md-0">간편비밀번호를 입력해주세요.</div>
            <div class="pt-12px mx-auto" style="max-width: 328px">
                <v-otp-input v-model="simplePassword" />
            </div>

            <div class="btn-wrap">
                <v-row class="row--sm">
                    <v-col>
                        <v-btn block outlined color="grey" class="v-size--xx-large">이전</v-btn>
                    </v-col>
                    <v-col>
                        <v-btn block color="primary" class="v-size--xx-large" @click="check">다음</v-btn>
                    </v-col>
                </v-row>
            </div>
        </template>

        <template v-if="type === 'order'">
            <div class="txt txt--xs txt--dark font-weight-medium text-center py-20px py-md-0">정기배송을 진행하시겠습니까?</div>

            <div class="btn-wrap">
                <v-row class="row--sm">
                    <v-col>
                        <v-btn block outlined color="grey" class="v-size--xx-large" @click="close">취소</v-btn>
                    </v-col>
                    <v-col>
                        <v-btn block color="primary" class="v-size--xx-large" @click="order">확인</v-btn>
                    </v-col>
                </v-row>
            </div>
        </template>

        <!-- <template v-if="type === 'confirm'">
            <div class="txt txt--xs txt--dark font-weight-medium text-center py-20px py-md-0">정기배송이 진행됩니다.</div>

            <div class="btn-wrap">
                <v-row class="row--sm">
                    <v-col>
                        <v-btn block color="primary" class="v-size--xx-large" @click="confirm">확인</v-btn>
                    </v-col>
                </v-row>
            </div>
        </template> -->
    </u-dialog>
</template>

<script>
import PageSection from "@/components/client/templates/page-section.vue";
import UDialog from "@/components/client/dumb/u-dialog.vue";
import ProductItem from "../product/product-item.vue";
import TitWrapSmall from "@/components/client/dumb/tit-wrap-small.vue";
import VerticalTable from "@/components/dumb/vertical-table.vue";
import ShopProductCarts from "../../shop/shop-product-carts.vue";
import ShopProductOptions from "../../shop/shop-product-options.vue";
import ShopProductSupplies from "../../shop/shop-product-supplies.vue";

export default {
    components: {
        PageSection,
        UDialog,
        ProductItem,
        TitWrapSmall,
        VerticalTable,
        ShopProductCarts,
        ShopProductOptions,
        ShopProductSupplies,
    },
    data() {
        return {
            shows: false,
            type: "check",
            simplePassword: null,
        };
    },
    methods: {
        open() {
            this.shows = true;
        },
        close() {
            this.value = null;
            this.shows = false;
        },
        next() {},
        check() {
            if (this.validate()) {
                this.type = "order";
            }
        },
        order() {
            this.shows = false;
            this.$emit("submit", this.simplePassword);
        },
        validate() {
            try {
                if (!this.simplePassword || this.simplePassword.length !== 6) throw new Error("간편 비밀번호를 입력해주세요");
                return true;
            } catch (error) {
                alert(error.message);
                return false;
            }
        },
    },
};
</script>
