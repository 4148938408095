<template>
    <v-simple-table class="v-data-table--default v-data-table--border v-data-table--simple-mobile v-data-table--no-hover text-md-center">
        <thead>
            <tr>
                <th style="width:auto;" class="text-center">상품정보</th>
                <th style="width:12%;" class="text-center">배송비</th>
                <th style="width:12%;" class="text-center">할인</th>
                <th style="width:15%;" class="text-center">상품금액(할인포함)</th>
            </tr>
        </thead>
        <tbody>
            <template v-for="{ deliveryMessage, totalDeliveryPrice, groups } in items">
                <template v-for="({ product, carts, totalDiscountPrice, productPrice, totalPrice }, index) in groups">
                    <tr :key="product._id">
                        <td class="text-left px-0px px-md-20px">
                            <v-row align-md="center" class="ma-n8px">
                                <v-col cols="auto" class="pa-8px">
                                    <v-card rounded="lg">
                                        <v-img min-width="100" :aspect-ratio="1 / 1" :src="`/res/shop/products/${product._id}/${product.thumb}`" />
                                    </v-card>
                                </v-col>
                                <v-col class="pa-8px">
                                    <router-link :to="`/shop/products/${product._id}`" class="d-block mb-12px">
                                        <span class="txt txt--sm txt--dark font-weight-medium">{{ product.name }}</span>
                                    </router-link>
                                    <div v-for="(cart, index) in carts" :key="index" :class="{ 'mt-4px': index != 0 }">
                                        <v-row align="center" class="row--xxs">
                                            <v-col v-if="cart._option || cart._supply" cols="12" sm="auto">
                                                <v-chip v-if="cart._option" x-small outlined color="grey">옵션</v-chip>
                                                <v-chip v-if="cart._supply" x-small outlined color="grey">추가</v-chip>
                                            </v-col>
                                            <v-col cols="12" sm="" class="txt txt--xs">
                                                <span>
                                                    {{ cart.name }} / {{ cart.amount.format() }}개 <span v-if="cart._supply"> (+{{ cart.price.format() }}원) </span>
                                                </span>
                                                <div v-if="cart.requestMessage">
                                                    {{ cart.requestMessage }}
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </v-col>
                            </v-row>
                        </td>
                        <td v-if="index == 0" :rowspan="groups.length">
                            <div class="d-md-none grey--text text--darken-4 font-weight-medium text-left mb-4px">배송비</div>
                            {{ totalDeliveryPrice ? `${totalDeliveryPrice?.format?.()}원` : "무료배송" }}
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-bind="attrs" v-on="on" small color="grey lighten-3" class="ml-4px cursor-pointer">mdi-help-circle-outline</v-icon>
                                </template>
                                <span>{{ deliveryMessage }}</span>
                            </v-tooltip>
                        </td>
                        <td>
                            <div class="d-md-none grey--text text--darken-4 font-weight-medium text-left mb-4px">할인</div>
                            {{ totalDiscountPrice?.format?.() }}원
                        </td>
                        <td>
                            <div class="d-md-none grey--text text--darken-4 font-weight-medium text-left mb-4px">상품금액(할인포함)</div>
                            <v-row no-gutters align="center">
                                <v-col cols="auto" md="12" order-md="2">
                                    <strong class="d-inline-flex align-center font-size-18 font-size-md-20 grey--text text--darken-4"> {{ totalPrice.format() }}<small class="font-size-14 font-weight-regular pl-2px">원</small> </strong>
                                </v-col>
                                <v-col v-if="productPrice != totalPrice" cols="auto" md="12" order-md="1">
                                    <span class="d-md-block font-size-14 text-decoration-line-through grey--text text--lighten-1 pl-8px pl-md-0">{{ productPrice.format() }}원</span>
                                </v-col>
                            </v-row>
                        </td>
                    </tr>
                </template>
            </template>
        </tbody>
    </v-simple-table>
</template>

<script>
import { mapState } from "vuex";

export default {
    props: {
        carts: { type: Array, default: () => [] },
        coupons: { type: Array, default: () => [] },
        postcode: { type: String },
    },
    computed: {
        ...mapState(["shippings", "islands"]),
        cartMaps() {
            return this.$getCartMaps(this.carts);
        },
        items() {
            return this.cartMaps.map((map) => {
                const groups = map.groups.map((group) => {
                    const totalDiscountPrice = this.$getDiscountPrice(group.carts) + this.$getCouponPrice(group.carts, this.coupons);
                    const productPrice = this.$getProductPrice(group.carts);
                    const totalPrice = productPrice - totalDiscountPrice;
                    return { ...group, totalDiscountPrice, productPrice, totalPrice };
                });

                const carts = groups.flatMap(({ carts }) => carts);
                const totalDeliveryPrice = this.$getDeliveryPrice(carts, this.shippings) + this.$getIslandPrice(carts, this.islands, this.postcode);

                return { ...map, totalDeliveryPrice, groups };
            });
        },
    },
};
</script>
