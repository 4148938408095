<template>
    <v-select v-bind="{ value, items }" dense outlined hide-details item-text="text" item-value="value" class="mw-md-160px" @change="(data) => $emit('input', data)" />
</template>

<script>
const items = [24, 48, 72].map((value) => ({ value, text: `${value}개 보기` }));
export default {
    props: {
        value: { type: Number, default: 24 },
    },
    data: () => ({
        items,
    }),
};
</script>

<style></style>
