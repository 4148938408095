<template>
    <vertical-form-table v-model="form" v-bind="{ mode, items }" @input="emit">
        <template #배송지선택>
            <v-radio-group v-model="mode" row hide-details>
                <v-radio value="default" label="기본배송지" :disabled="!defaultReceiver?._id" />
                <v-radio value="form" label="신규배송지" />
                <destination-select-dialog v-model="form.receiver" @input="emit">
                    <template #activator="{ attrs, on }">
                        <v-btn outlined color="grey" class="v-size--xx-small" v-bind="attrs" v-on="on">배송지 목록</v-btn>
                    </template>
                </destination-select-dialog>
            </v-radio-group>
        </template>
        <template #주문자동일 v-if="mode != 'default'">
            <v-checkbox v-model="sameAsUser" hide-details label="주문자 정보와 동일하게 적용" @change="setSameAsUser" />
        </template>
        <template #배송지명 v-if="mode != 'default'">
            <v-text-field v-model="form.receiver.title" v-bind="attrs_input" placeholder="배송지명을 입력하세요." @input="emit" />
            <div class="pt-8px">
                <v-checkbox v-model="form.receiver.isDefault" hide-details label="기본배송지로 선택" @change="emit" />
            </div>
        </template>
    </vertical-form-table>
</template>

<script>
import api from "@/api";
import { attrs_input } from "@/assets/variables";

import VerticalFormTable from "@/components/dumb/vertical-form-table.vue";
import DestinationSelectDialog from "../destinations/destination-select-dialog.vue";
import { mapState } from "vuex";

const initReceiver = (receiver = {}) => ({
    ...receiver,

    title: receiver?.title || null,
    name: receiver?.name || null,
    phone: receiver?.phone || null,
    email: receiver?.email || null,
    postcode: receiver?.postcode || null,
    address1: receiver?.address1 || null,
    address2: receiver?.address2 || null,
    isDefault: receiver?.isDefault || null,
});

export default {
    components: { VerticalFormTable, DestinationSelectDialog },
    props: {
        value: { type: Object, default: () => ({ receiver: initReceiver() }) },
    },
    data: () => ({
        form: { receiver: initReceiver() },
        defaultReceiver: initReceiver(),
        sameAsUser: false,

        mode: "default",
        attrs_input,
    }),
    computed: {
        ...mapState(["accessToken", "user"]),
        items() {
            const items = [];

            items.push({ term: "배송지선택" });
            if (this.mode == "form") {
                items.push({ term: "주문자동일", headerProps: { containerProps: { class: "white--text" } } });
            }
            items.push({ key: "receiver.title", term: "배송지명", type: "text", required: true });
            items.push({ key: "receiver.name", term: "이름", type: "text", required: true, placeholder: "이름을 입력하세요." });
            items.push({ key: "receiver.phone", term: "연락처", type: "phone", required: true });
            items.push({ key: "receiver", term: "주소", type: "address", required: true, persistentPlaceholder: true, btnAttrs: { large: true, color: "primary" } });

            return items.map((item) => ({ ...item, outlined: true }));
        },
        isDefaultItem() {
            const { _id: _default } = this.defaultReceiver || {};
            return (_default && _default == this.form?.receiver?._id) || false;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        value() {
            this.sync();
        },
        "value.receiver"() {
            this.sync();
        },
        mode() {
            switch (this.mode) {
                case "default": {
                    this.form = { receiver: initReceiver(this.defaultReceiver) };
                    this.emit();
                    break;
                }
                case "form": {
                    this.form = { receiver: initReceiver() };
                    this.emit();
                    break;
                }
            }
        },
        isDefaultItem() {
            this.sync();
        },
        accessToken() {
            this.init();
        },
    },
    methods: {
        sync() {
            this.form = { receiver: initReceiver(this.value.receiver) };

            if (this.isDefaultItem) this.mode = "default";
            else if (this.form?.receiver?._id) this.mode = "neither";
            else this.mode = "form";
        },
        emit() {
            const { ...receiver } = this.form?.receiver;
            this.$emit("input", { ...this.value, receiver });
        },
        async init() {
            if (!this.accessToken) return;
            if (this.loading) return;
            else this.loading = true;

            try {
                this.defaultReceiver = (await api.v1.me.destinations.gets({ headers: { limit: 1 } }))?.destinations[0];
                this.form = { receiver: initReceiver(this.defaultReceiver) };
                this.emit();
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
        setSameAsUser(sameAsUser) {
            if (sameAsUser) {
                this.form.receiver = initReceiver({
                    ...this.form,

                    name: this.user?.name,
                    phone: this.user?.phone,
                    email: this.user?.email,
                    postcode: this.user?.postcode,
                    address1: this.user?.address1,
                    address2: this.user?.address2,
                });
                this.emit();
            }
        },
    },
};
</script>

<style></style>
