<template>
    <u-dialog v-model="shows" title="배송지 선택하기" width="720">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>

        <div v-bind="{ items, loading }" class="border-t border-2 border-dark">
            <v-card v-for="item in items" :key="item?._id">
                <div class="py-16px">
                    <v-row align-md="center" class="ma-n8px">
                        <v-col class="pa-8px">
                            <v-card color="transparent">
                                <div class="txt txt--sm txt--dark font-weight-medium">
                                    <v-row align="center" class="row--xs">
                                        <v-col cols="auto"> {{ item.title }} ({{ item.name }}) </v-col>
                                        <v-col v-if="item.isDefault" cols="auto">
                                            <v-chip x-small color="primary">기본배송지</v-chip>
                                        </v-col>
                                    </v-row>
                                </div>
                                <div class="txt txt--xs txt--light mt-4px mt-md-8px">
                                    <div>{{ item?.phone }}</div>
                                    <div>{{ item.address }}</div>
                                </div>
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="auto" class="pa-8px">
                            <div class="w-md-150px">
                                <v-row class="row--xs">
                                    <v-col cols="4" md="12" class="mw-100 flex-grow-1 flex-shrink-0">
                                        <v-btn block outlined color="primary" @click="select(item)">선택</v-btn>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-col>
                    </v-row>
                </div>
                <v-divider />
            </v-card>
        </div>

        <div class="pagination-wrap pagination-wrap--sm">
            <v-pagination v-model="page" :length="pageCount" :total-visible="11" />
        </div>

        <!-- <v-data-table v-bind="{ items, headers, loading }" hide-default-footer disable-filtering disable-pagination disable-sort>
            <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
            <template #[`item.title`]="{ item }">
                <v-layout column align-center>
                    <span> {{ item?.title }} </span>
                    <span> {{ item?.name }} </span>
                    <span v-if="item.isDefault"> <v-chip outlined color="primary" class="rounded-0">기본배송지</v-chip> </span>
                </v-layout>
            </template>
            <template #[`item.address`]="{ item }">
                <v-layout column align-start>
                    <span> {{ item?.postcode }} </span>
                    <span> {{ item?.address1 }} </span>
                    <span> {{ item?.address2 }} </span>
                </v-layout>
            </template>
            <template #[`item.actions`]="{ item }">
                <v-btn large outlined tile color="primary" @click="select(item)">선택</v-btn>
            </template>
            <template #footer>
                <v-pagination v-model="page" :length="pageCount" :total-visible="11" />
            </template>
        </v-data-table> -->
    </u-dialog>
</template>

<script>
import api from "@/api";
import { initDestination } from "@/assets/variables";

import UDialog from "@/components/client/dumb/u-dialog.vue";
import VAddressField from "@/components/plugins/vuetify/v-address-field.vue";
import VerticalFormTable from "@/components/dumb/vertical-form-table.vue";
import { mapState } from "vuex";

const headers = [
    { width: 140, text: "배송지", value: "title", align: "center" },
    { text: "주소", value: "address", align: "center" },
    { width: 160, text: "연락처", value: "phone", align: "center" },
    { width: 120, text: "", value: "actions", align: "end" },
];

export default {
    components: {
        UDialog,
        VAddressField,
        VerticalFormTable,
    },
    props: {
        value: { type: Object, default: initDestination },
    },
    data: () => ({
        destinations: [],

        page: 1,
        limit: 10,
        summary: { totalCount: 0 },

        shows: false,
        loading: false,

        headers,
    }),
    computed: {
        ...mapState(["accessToken"]),
        items() {
            return [...this.destinations].map((item, index) => ({ index: this.summary.totalCount - (this.page - 1) * this.limit - index, ...item }));
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
    },
    watch: {
        shows() {
            if (this.shows) this.init();
        },
        page() {
            this.search();
        },
        accessToken() {
            this.search();
        },
    },
    methods: {
        async init() {
            try {
                this.destinations = [];
                this.page = 1;

                this.search();
            } catch (error) {
                this.$handleError(error);
            }
        },
        async search() {
            if (!this.accessToken) return;
            if (this.loading) return;
            else this.loading = true;

            try {
                let { skip, limit } = this;
                var { summary, destinations } = await api.v1.me.destinations.gets({
                    headers: { skip, limit },
                });

                this.summary = summary;
                this.destinations = destinations;
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
        async select(item) {
            this.$emit("input", { ...item });
            this.shows = false;
        },
    },
};
</script>

<style></style>
