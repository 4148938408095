import { render, staticRenderFns } from "./product-view.vue?vue&type=template&id=26faa244&scoped=true&"
import script from "./product-view.vue?vue&type=script&lang=js&"
export * from "./product-view.vue?vue&type=script&lang=js&"
import style0 from "./product-view.vue?vue&type=style&index=0&id=26faa244&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26faa244",
  null
  
)

export default component.exports