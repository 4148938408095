var render = function render(){
  var _vm$user, _vm$user2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "txt txt--xs py-16px py-sm-24px"
  }, [_c('tit-wrap-small', {
    attrs: {
      "title": "적립금 혜택으로 돈버는 쇼핑!"
    }
  }, [(_vm$user = _vm.user) !== null && _vm$user !== void 0 && _vm$user.title ? _c('div', {
    staticClass: "txt--light"
  }, [_vm._v(" 내 회원등급 "), _c('span', {
    staticClass: "primary--text"
  }, [_vm._v(_vm._s((_vm$user2 = _vm.user) === null || _vm$user2 === void 0 ? void 0 : _vm$user2.title))])]) : _vm._e()]), _c('product-benefits-card', {
    attrs: {
      "_product": _vm.product._id
    }
  })], 1), _c('v-divider')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }