<template>
    <client-page>
        <page-section>
            <!-- 검색결과 -->
            <div class="tit-wrap text-center">
                <h2 class="tit tit--md"><span class="primary--text">{{ $route.query.searchValue }}</span>에 대한 검색결과</h2>
            </div>

            <!-- 상품 리스트 -->
            <section class="product-lists">
                <div class="product-lists-head">
                    <div class="product-lists__total">
                        <em>{{ summary.totalCount.format() }}</em> 개의 상품이 있습니다.
                    </div>
                    <div class="product-lists__order">
                        <div class="divider-group">
                            <ul>
                                <li><a @click="search({ best: true, newly: undefined, byPrice: undefined })" :class="{ on: $route.query.best }">베스트</a></li>
                                <li><a @click="search({ best: undefined, newly: true, byPrice: undefined })" :class="{ on: $route.query.newly }">신상품</a></li>
                                <li><a @click="search({ best: undefined, newly: undefined, byPrice: -1 })" :class="{ on: $route.query.byPrice == -1 }">높은 가격순</a></li>
                                <li><a @click="search({ best: undefined, newly: undefined, byPrice: 1 })" :class="{ on: $route.query.byPrice == 1 }">낮은 가격순</a></li>
                            </ul>
                        </div>
                        <div class="product-lists__select">
                            <select v-model="limit" class="product-lists__select-option">
                                <option :value="20">20개 보기</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="product-lists-body">
                    <shop-product-items>
                        <shop-product-item v-for="(product, index) in products" :key="index" :value="product" />
                    </shop-product-items>

                    <div class="product-lists__pagination">
                        <pagination-component v-model="page" :count="pageCount" @input="search"></pagination-component>
                    </div>
                </div>
            </section>
        </page-section>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";

import ShopProductItems from "@/components/client/shop/shop-product-items.vue";
import ShopProductItem from "@/components/client/shop/shop-product-item.vue";

import ShopCategories from "../../../components/client/shop/shop-categories.vue";
import PaginationComponent from "../../../components/client/pagination-component.vue";

export default {
    components: {
        ClientPage,
        PageSection,

        ShopProductItems,
        ShopProductItem,

        ShopCategories,
        PaginationComponent,
    },
    data: () => {
        return {
            page: 1,
            pageCount: 0,
            limit: 20,
            summary: { totalCount: 0 },
            products: [],
        };
    },
    created() {},
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                await this.getProducts();
            } catch (error) {
                console.error(error);
            }
        },

        async search(filter) {
            try {
                this.$router.push({
                    query: Object.assign({}, this.$route.query, filter),
                });

                await this.getProducts();
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
                else alert(error.message);
            }
        },

        getProducts() {
            return new Promise(async (resolve, reject) => {
                try {
                    var filter = Object.assignDefined({}, this.$route.query);
                    var res = await this.$http.get("/api/v1/shop/products", {
                        headers: {
                            skip: (this.page - 1) * this.limit,
                            limit: this.limit,
                        },
                        params: filter,
                    });

                    this.summary = res.data.summary;
                    this.pageCount = Math.ceil(res.data.summary.totalCount / this.limit);
                    this.products = res.data.products;

                    resolve();
                } catch (error) {
                    reject(error);
                }
            });
        },
    },
    watch: {
        "$route.query.searchKey"() {
            this.init();
        },
        "$route.query.searchValue"() {
            this.init();
        },
    },
};
</script>
