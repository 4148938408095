<template>
    <v-row align="center" class="row--xs">
        <!-- <v-col cols="auto">
            <v-select v-model="filter.searchKey" dense outlined hide-details label="제목" item-text="text" item-value="value" class="w-120px" v-bind="{ loading }" />
        </v-col> -->
        <v-col cols="">
            <v-text-field v-model="filter.searchValue" dense outlined hide-details placeholder="검색어를 입력하세요" v-bind="{ loading }" @keydown.enter="emit" />
        </v-col>
        <v-col cols="12" sm="auto">
            <v-btn color="grey" class="w-100 w-sm-80px" v-bind="{ loading }" @click="emit">검색</v-btn>
        </v-col>
    </v-row>
</template>

<script>
const initFilter = (filter = {}) => ({ ...(filter || {}), searchKey: filter?.searchKey || "subject", searchValue: filter?.searchValue || null });
export default {
    props: {
        value: { type: Object, default: initFilter },
        loading: { type: Boolean, default: false },
    },
    data: () => ({
        filter: initFilter(),
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.filter = initFilter(this.value);
        },
        emit() {
            this.$emit("input", this.filter);
        },
    },
};
</script>

<style></style>
