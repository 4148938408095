var render = function render(){
  var _vm$category;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('page-section', {
    staticClass: "page-section--first page-section--last"
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(_vm._s(((_vm$category = _vm.category) === null || _vm$category === void 0 ? void 0 : _vm$category.name) || "전체보기"))]), _c('section', {
    staticClass: "product-lists"
  }, [_c('div', {
    staticClass: "product-lists-head"
  }, [_c('div', {
    staticClass: "product-lists__total"
  }, [_c('span', [_c('em', [_vm._v(_vm._s(_vm.summary.totalCount.format()))]), _vm._v(" 개의 상품이 있습니다. ")])]), _c('div', {
    staticClass: "product-lists__order"
  }, [_c('div', {
    staticClass: "divider-group"
  }, [_c('product-list-sort')], 1), _c('div', {
    staticClass: "product-lists__select"
  }, [_c('product-list-limit', {
    model: {
      value: _vm.limit,
      callback: function ($$v) {
        _vm.limit = $$v;
      },
      expression: "limit"
    }
  })], 1)])]), _c('div', {
    staticClass: "product-lists-body"
  }, [_c('shop-product-items', _vm._l(_vm.products, function (product, index) {
    return _c('shop-product-item', {
      key: index,
      attrs: {
        "value": product
      }
    });
  }), 1), _c('div', {
    staticClass: "product-lists__pagination"
  }, [_c('pagination-component', {
    attrs: {
      "value": _vm.page,
      "count": _vm.pageCount
    },
    on: {
      "input": function (page) {
        return _vm.$router.push({
          query: Object.assign({}, _vm.$route.query, {
            page
          })
        });
      }
    }
  })], 1)], 1)])], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }