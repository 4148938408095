var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ul', _vm._l(_vm.items, function (_ref) {
    var text = _ref.text,
      to = _ref.to,
      attrs = _ref.attrs;
    return _c('li', {
      key: text
    }, [_c('router-link', _vm._b({}, 'router-link', Object.assign({}, attrs, {
      to
    }), false), [_vm._v(" " + _vm._s(text) + " ")])], 1);
  }), 0);

}
var staticRenderFns = []

export { render, staticRenderFns }