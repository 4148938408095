var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.show ? _c('transition', {
    attrs: {
      "name": "modal",
      "appear": ""
    }
  }, [_c('div', {
    staticClass: "modal-container"
  }, [_c('div', {
    staticClass: "modal-wrapper"
  }, [_c('div', {
    staticClass: "modal"
  }, [_c('div', {
    staticClass: "modal__head"
  }, [_c('h1', {
    staticClass: "modal__title"
  }, [_vm._v("할인 가능 쿠폰 받기")]), _c('button', {
    staticClass: "modal__close",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.close
    }
  }, [_c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  }, [_c('path', {
    attrs: {
      "d": "M0.222,1.636 L1.636,0.221 L15.778,14.363 L14.364,15.778 L0.222,1.636 Z"
    }
  }), _c('path', {
    attrs: {
      "d": "M14.364,0.221 L15.778,1.636 L1.636,15.778 L0.222,14.363 L14.364,0.221 Z"
    }
  })])])]), _c('div', {
    staticClass: "modal__body"
  }, [_c('div', {
    staticClass: "download-coupon"
  }, [_c('div', {
    staticClass: "download-coupon__box",
    staticStyle: {
      "background-color": "#f9b428"
    }
  }, [_c('span', {
    staticClass: "download-coupon__badge"
  }, [_vm._v("COUPON")]), _c('span', {
    staticClass: "download-coupon__title"
  }, [_vm._v(_vm._s(_vm.coupon.name))]), _c('span', {
    staticClass: "download-coupon__num"
  }, [_c('em', [_vm._v(_vm._s(_vm.coupon.amount))]), _vm._v(_vm._s(_vm.coupon.unit))])]), _c('p', {
    staticClass: "download-coupon__pr"
  }, [_c('b', [_vm._v("쿠폰사용 및 유의사항 안내")]), _c('br'), _vm.coupon.expiration.enabled ? _c('span', [_c('b', [_vm._v("사용기간 :")]), _vm.coupon.expiration.type == 'dates' ? _c('span', [_vm._v(" " + _vm._s(_vm.coupon.expiration.startDate.toDate()) + " ~ " + _vm._s(_vm.coupon.expiration.endDate.toDate()))]) : _vm._e(), _vm.coupon.expiration.type == 'days' ? _c('span', [_vm._v(" 발급일로부터 " + _vm._s(_vm.coupon.expiration.days.format()) + "일 이내")]) : _vm._e()]) : _vm._e()])])]), _c('div', {
    staticClass: "modal__foot"
  }, [_c('div', {
    staticClass: "modal__buttons"
  }, [_c('button', {
    staticClass: "button button--primary button--download",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.download
    }
  }, [_c('span', [_vm._v("쿠폰받기")])])])])])])])]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }