var render = function render(){
  var _vm$user, _vm$user$point, _vm$user$point$format, _vm$order, _vm$order2, _vm$order2$point, _vm$order2$point$form;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('page-section', {
    staticClass: "page-section--first page-section--last"
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v("주문/결제")]), _c('page-section', {
    staticClass: "page-section--sm pt-0"
  }, [_c('template', {
    slot: "containerImmersive"
  }, [_c('order-carts', _vm._b({}, 'order-carts', {
    carts: _vm.carts,
    coupons: _vm.coupons,
    postcode: _vm.postcode
  }, false))], 1)], 2), _c('div', {
    staticClass: "fixed-section"
  }, [_c('div', {
    staticClass: "fixed-contents"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "",
      "xl": "9"
    }
  }, [_c('div', {
    staticClass: "pr-xl-36px"
  }, [_c('page-section', {
    staticClass: "page-section--sm pt-0"
  }, [_c('template', {
    slot: "containerImmersive"
  }, [_c('tit-wrap-line', {
    attrs: {
      "noDivider": "",
      "title": "구매자 정보입력"
    }
  }), _c('order-sender', {
    model: {
      value: _vm.order.sender,
      callback: function ($$v) {
        _vm.$set(_vm.order, "sender", $$v);
      },
      expression: "order.sender"
    }
  })], 1)], 2), _c('page-section', {
    staticClass: "page-section--sm pt-0"
  }, [_c('template', {
    slot: "containerImmersive"
  }, [_c('tit-wrap-line', {
    attrs: {
      "noDivider": "",
      "title": "수취인 정보입력"
    }
  }), _c('order-receiver', {
    model: {
      value: _vm.order,
      callback: function ($$v) {
        _vm.order = $$v;
      },
      expression: "order"
    }
  })], 1)], 2), _c('page-section', {
    staticClass: "page-section--sm pt-0"
  }, [_c('template', {
    slot: "containerImmersive"
  }, [_c('tit-wrap-line', {
    attrs: {
      "noDivider": "",
      "title": "요청사항"
    }
  }), _c('vertical-table', {
    attrs: {
      "items": [{
        term: '요청사항'
      }]
    }
  }, [_c('v-textarea', {
    attrs: {
      "slot": "요청사항",
      "placeholder": "요청사항을 입력하세요",
      "outlined": "",
      "hide-details": ""
    },
    slot: "요청사항",
    model: {
      value: _vm.order.requestMessage,
      callback: function ($$v) {
        _vm.$set(_vm.order, "requestMessage", $$v);
      },
      expression: "order.requestMessage"
    }
  })], 1)], 1)], 2), _vm.$route.query.deliveryType === 'single' ? _c('page-section', {
    staticClass: "page-section--sm pt-0"
  }, [_c('template', {
    slot: "containerImmersive"
  }, [_c('tit-wrap-line', {
    attrs: {
      "noDivider": "",
      "title": "적립금사용"
    }
  }, [_c('span', {
    staticClass: "d-flex align-center txt txt--sm"
  }, [_c('span', {
    staticClass: "pr-10px pr-md-16px"
  }, [_vm._v("보유 적립금")]), _c('span', {
    staticClass: "primary--text"
  }, [_c('strong', {
    staticClass: "txt--lg"
  }, [_vm._v(_vm._s(((_vm$user = _vm.user) === null || _vm$user === void 0 ? void 0 : (_vm$user$point = _vm$user.point) === null || _vm$user$point === void 0 ? void 0 : (_vm$user$point$format = _vm$user$point.format) === null || _vm$user$point$format === void 0 ? void 0 : _vm$user$point$format.call(_vm$user$point)) || 0))]), _vm._v(" 원 ")])])]), _c('order-point', {
    attrs: {
      "max": _vm.maxUsablePoint
    },
    model: {
      value: _vm.order.point,
      callback: function ($$v) {
        _vm.$set(_vm.order, "point", $$v);
      },
      expression: "order.point"
    }
  })], 1)], 2) : _vm._e(), _vm.$route.query.deliveryType === 'single' ? _c('page-section', {
    staticClass: "page-section--sm pt-0 pb-lg-0"
  }, [_c('template', {
    slot: "containerImmersive"
  }, [_c('tit-wrap-line', {
    attrs: {
      "noDivider": "",
      "title": "결제방법"
    }
  }), _c('v-simple-table', {
    staticClass: "v-data-table--default v-data-table--no-hover"
  }, [_c('tbody', [_c('tr', [_c('td', [_c('v-radio-group', {
    attrs: {
      "row": "",
      "hide-details": ""
    },
    model: {
      value: _vm.order.paymentMethod,
      callback: function ($$v) {
        _vm.$set(_vm.order, "paymentMethod", $$v);
      },
      expression: "order.paymentMethod"
    }
  }, [_c('v-radio', {
    attrs: {
      "name": "payment_method",
      "value": "card",
      "label": "신용카드"
    }
  }), _c('v-radio', {
    attrs: {
      "name": "payment_method",
      "value": "bank",
      "label": "실시간 계좌이체"
    }
  }), _c('v-radio', {
    attrs: {
      "name": "payment_method",
      "value": "account",
      "label": "무통장 입금"
    }
  })], 1)], 1)])])])], 1)], 2) : _vm._e()], 1)]), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "auto",
      "xl": "3"
    }
  }, [_c('page-section', {
    staticClass: "page-section--last page-section--sm pt-0 pb-lg-0 fixed-contents__fixed"
  }, [_c('template', {
    slot: "containerImmersive"
  }, [_c('v-card', {
    staticClass: "mt-12px",
    attrs: {
      "rounded": "",
      "elevation": "4"
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-sm-24px"
  }, [_c('tit-wrap-line', {
    attrs: {
      "noDivider": "",
      "title": "결제상세"
    }
  }), _c('v-divider'), _c('div', {
    staticClass: "py-12px border-b"
  }, [_c('v-row', {
    staticClass: "row--xs txt txt--xs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('span', [_vm._v("총 상품금액")])]), _c('v-col', {
    staticClass: "font-weight-medium text-right",
    attrs: {
      "cols": "6"
    }
  }, [_c('span', {
    staticClass: "txt--dark"
  }, [_vm._v(" " + _vm._s(_vm.productPrice.format()) + " 원 ")])])], 1)], 1), _vm.discountPrice ? _c('div', {
    staticClass: "py-12px border-b"
  }, [_c('v-row', {
    staticClass: "row--xs txt txt--xs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('span', [_vm._v("총 상품할인")])]), _c('v-col', {
    staticClass: "font-weight-medium text-right",
    attrs: {
      "cols": "6"
    }
  }, [_c('span', {
    staticClass: "txt--dark"
  }, [_vm._v(" " + _vm._s(_vm.discountPrice.format()) + " 원 ")])])], 1)], 1) : _vm._e(), _c('div', {
    staticClass: "py-12px border-b"
  }, [_c('v-row', {
    staticClass: "row--xs txt txt--xs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('span', [_vm._v("배송비")])]), _c('v-col', {
    staticClass: "font-weight-medium text-right",
    attrs: {
      "cols": "6"
    }
  }, [_c('span', {
    staticClass: "txt--dark"
  }, [_vm._v(" " + _vm._s(_vm.deliveryPrice.format()) + " 원 ")])])], 1)], 1), _vm.islandPrice ? _c('div', {
    staticClass: "py-12px border-b"
  }, [_c('v-row', {
    staticClass: "row--xs txt txt--xs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('span', [_vm._v("추가 배송비 (도서산간)")])]), _c('v-col', {
    staticClass: "font-weight-medium text-right",
    attrs: {
      "cols": "6"
    }
  }, [_c('span', {
    staticClass: "txt--dark"
  }, [_vm._v(" " + _vm._s(_vm.islandPrice.format()) + " 원 ")])])], 1)], 1) : _vm._e(), (_vm$order = _vm.order) !== null && _vm$order !== void 0 && _vm$order.point ? _c('div', {
    staticClass: "py-12px border-b"
  }, [_c('v-row', {
    staticClass: "row--xs txt txt--xs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('span', [_vm._v("사용 적립금")])]), _c('v-col', {
    staticClass: "font-weight-medium text-right",
    attrs: {
      "cols": "6"
    }
  }, [_c('span', {
    staticClass: "txt--dark"
  }, [_vm._v(" " + _vm._s((_vm$order2 = _vm.order) === null || _vm$order2 === void 0 ? void 0 : (_vm$order2$point = _vm$order2.point) === null || _vm$order2$point === void 0 ? void 0 : (_vm$order2$point$form = _vm$order2$point.format) === null || _vm$order2$point$form === void 0 ? void 0 : _vm$order2$point$form.call(_vm$order2$point)) + " 원 ")])])], 1)], 1) : _vm._e(), _c('div', {
    staticClass: "py-12px border-b"
  }, [_c('v-row', {
    staticClass: "row--xs txt txt--xs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('span', [_vm._v("총 결제금액")])]), _c('v-col', {
    staticClass: "font-weight-medium text-right",
    attrs: {
      "cols": "6"
    }
  }, [_c('span', {
    staticClass: "primary--text"
  }, [_c('strong', {
    staticClass: "txt--lg"
  }, [_vm._v(_vm._s(_vm.totalPrice.format()))]), _vm._v(" 원 ")])])], 1)], 1), _c('div', {
    staticClass: "pt-16px"
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "12"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-large",
    attrs: {
      "block": "",
      "outlined": "",
      "color": "primary",
      "to": "/"
    }
  }, 'v-btn', {
    loading: _vm.loading
  }, false), [_vm._v("취소하기")])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "12"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-large",
    attrs: {
      "block": "",
      "color": "primary"
    },
    on: {
      "click": _vm.pay
    }
  }, 'v-btn', {
    loading: _vm.loading
  }, false), [_vm._v(_vm._s(_vm.$route.query.deliveryType === "regular" ? "정기배송 신청하기" : "결제하기"))])], 1)], 1)], 1)], 1)])], 1)], 2), _c('div', {
    staticClass: "fixed-contents__fixed-con"
  })], 1)], 1)], 1)])], 2), _c('v-fade-transition', [_c('v-overlay', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      "color": "white"
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "indeterminate": "",
      "size": "100",
      "width": "5",
      "color": "primary"
    }
  })], 1)], 1), _c('modal-order-bank', {
    ref: "modal-order-bank",
    attrs: {
      "totalPrice": _vm.totalPrice
    },
    on: {
      "pay": _vm.submit
    },
    model: {
      value: _vm.order,
      callback: function ($$v) {
        _vm.order = $$v;
      },
      expression: "order"
    }
  }), _c('nice-pay', {
    ref: "nicePay"
  }), _c('nice-pay-u', {
    ref: "nicePayU"
  }), _c('simple-password-check', {
    ref: "simplePasswordCheck",
    on: {
      "submit": function (simplePassword) {
        return _vm.submit(simplePassword);
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }