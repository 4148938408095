<template id="modal-template">
    <u-dialog v-model="show" name="modal" title="문의하기">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <tit-wrap-small title="문의 제목"></tit-wrap-small>
        <v-text-field v-model="inquire.subject" dense outlined hide-details placeholder="문의 제목을 입력해주세요." />

        <page-section class="page-section--xs pb-0">
            <template #containerImmersive>
                <tit-wrap-small title="문의 내용"></tit-wrap-small>
                <v-textarea v-model="inquire.content" dense outlined hide-details placeholder="문의 내용을 입력해주세요." />
                <div class="pt-8px">
                    <v-checkbox v-model="inquire.secret" :value="true" hide-details label="비공개" />
                </div>
            </template>
        </page-section>

        <page-section class="page-section--xs pb-0">
            <template #containerImmersive>
                <tit-wrap-small title="이미지 등록"></tit-wrap-small>
                <form-files v-model="inquire.images" />
            </template>
        </page-section>

        <template #actions>
            <v-row justify="center" class="row--sm">
                <v-col cols="12" md="6">
                    <v-btn block color="primary" class="v-size--xx-large" @click="submit">문의하기</v-btn>
                </v-col>
            </v-row>
        </template>
    </u-dialog>
</template>

<script>
import PageSection from "@/components/client/templates/page-section.vue";
import UDialog from "@/components/client/dumb/u-dialog.vue";
import TitWrapSmall from "@/components/client/dumb/tit-wrap-small.vue";
import FormFiles from "../dumb/form-files.vue";
export default {
    props: {
        product: { type: Object, default: null },
    },
    components: {
        PageSection,
        UDialog,
        TitWrapSmall,
        FormFiles,
    },
    data: () => ({
        show: false,

        inquire: {
            subject: null,
            content: null,
            secret: false,
            images: [],
        },
    }),
    methods: {
        async submit() {
            try {
                if (!this.accessToken) throw new Error("로그인 이후 이용가능합니다");

                var { inquire } = await this.postInquire(
                    Object.assign(
                        {
                            _product: this.product._id,
                        },
                        this.inquire
                    )
                );

                for (var i in this.inquire.images) {
                    if (this.inquire.images[i]) await this.postFile(inquire._id, i, this.inquire.images[i]);
                }

                alert("문의가 완료되었습니다");
                this.shows = false;
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        postInquire(inquire) {
            return new Promise(async (resolve, reject) => {
                try {
                    var res = await this.$http.post("/api/v1/me/inquires", inquire);

                    resolve(res.data);
                } catch (error) {
                    reject(error);
                }
            });
        },
        postFile(_inquire, index, file) {
            var data = new FormData();
            data.append("image", file);
            var headers = { "Content-Type": "multipart/formdata" };
            return this.$http.post(`/api/v1/me/inquires/${_inquire}/images/${index}`, data, { headers });
        },
    },
    computed: {
        accessToken() {
            return this.$store.state.accessToken;
        },
    },
};
</script>
