<template>
    <client-page>
        <page-section class="page-section--first page-section--last">
            <template slot="tit">주문/결제</template>

            <!-- 장바구니 -->
            <page-section class="page-section--sm pt-0">
                <template slot="containerImmersive">
                    <order-carts v-bind="{ carts, coupons, postcode }" />
                </template>
            </page-section>
            <!-- // 장바구니 -->

            <div class="fixed-section">
                <div class="fixed-contents">
                    <v-row>
                        <v-col cols="12" lg="" xl="9">
                            <div class="pr-xl-36px">
                                <!-- 구매자정보 -->
                                <page-section class="page-section--sm pt-0">
                                    <template slot="containerImmersive">
                                        <!-- head -->
                                        <tit-wrap-line noDivider title="구매자 정보입력"> </tit-wrap-line>
                                        <order-sender v-model="order.sender" />
                                    </template>
                                </page-section>
                                <!-- // 구매자정보 -->

                                <!-- 수취인정보 -->
                                <page-section class="page-section--sm pt-0">
                                    <template slot="containerImmersive">
                                        <!-- head -->
                                        <tit-wrap-line noDivider title="수취인 정보입력"> </tit-wrap-line>

                                        <order-receiver v-model="order" />
                                    </template>
                                </page-section>
                                <!-- // 수취인정보 -->

                                <!-- 요청사항 -->
                                <page-section class="page-section--sm pt-0">
                                    <template slot="containerImmersive">
                                        <!-- head -->
                                        <tit-wrap-line noDivider title="요청사항"> </tit-wrap-line>

                                        <vertical-table :items="[{ term: '요청사항' }]"> <v-textarea slot="요청사항" v-model="order.requestMessage" placeholder="요청사항을 입력하세요" outlined hide-details /> </vertical-table>
                                    </template>
                                </page-section>
                                <!-- // 요청사항 -->

                                <!-- 적립금사용 -->
                                <page-section v-if="$route.query.deliveryType === 'single'" class="page-section--sm pt-0">
                                    <template slot="containerImmersive">
                                        <!-- head -->
                                        <tit-wrap-line noDivider title="적립금사용">
                                            <span class="d-flex align-center txt txt--sm">
                                                <span class="pr-10px pr-md-16px">보유 적립금</span>
                                                <span class="primary--text">
                                                    <strong class="txt--lg">{{ user?.point?.format?.() || 0 }}</strong> 원
                                                </span>
                                            </span>
                                        </tit-wrap-line>
                                        <order-point v-model="order.point" :max="maxUsablePoint" />
                                    </template>
                                </page-section>
                                <!-- // 적립금사용 -->

                                <!-- 결제방법 -->
                                <page-section v-if="$route.query.deliveryType === 'single'" class="page-section--sm pt-0 pb-lg-0">
                                    <template slot="containerImmersive">
                                        <!-- head -->
                                        <tit-wrap-line noDivider title="결제방법"> </tit-wrap-line>

                                        <v-simple-table class="v-data-table--default v-data-table--no-hover">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <v-radio-group v-model="order.paymentMethod" row hide-details>
                                                            <v-radio name="payment_method" value="card" label="신용카드" />
                                                            <v-radio name="payment_method" value="bank" label="실시간 계좌이체" />
                                                            <v-radio name="payment_method" value="account" label="무통장 입금" />
                                                        </v-radio-group>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </v-simple-table>
                                    </template>
                                </page-section>
                                <!-- // 결제방법 -->
                            </div>
                        </v-col>
                        <v-col cols="12" lg="auto" xl="3">
                            <!-- 결제상세 -->
                            <page-section class="page-section--last page-section--sm pt-0 pb-lg-0 fixed-contents__fixed">
                                <template slot="containerImmersive">
                                    <v-card rounded="" elevation="4" class="mt-12px">
                                        <div class="pa-16px pa-sm-24px">
                                            <!-- head -->
                                            <tit-wrap-line noDivider title="결제상세"></tit-wrap-line>

                                            <v-divider />
                                            <div class="py-12px border-b">
                                                <v-row align="center" class="row--xs txt txt--xs">
                                                    <v-col cols="6">
                                                        <span>총 상품금액</span>
                                                    </v-col>
                                                    <v-col cols="6" class="font-weight-medium text-right">
                                                        <span class="txt--dark"> {{ productPrice.format() }} 원 </span>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                            <div class="py-12px border-b" v-if="discountPrice">
                                                <v-row align="center" class="row--xs txt txt--xs">
                                                    <v-col cols="6">
                                                        <span>총 상품할인</span>
                                                    </v-col>
                                                    <v-col cols="6" class="font-weight-medium text-right">
                                                        <span class="txt--dark"> {{ discountPrice.format() }} 원 </span>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                            <div class="py-12px border-b">
                                                <v-row align="center" class="row--xs txt txt--xs">
                                                    <v-col cols="6">
                                                        <span>배송비</span>
                                                    </v-col>
                                                    <v-col cols="6" class="font-weight-medium text-right">
                                                        <span class="txt--dark"> {{ deliveryPrice.format() }} 원 </span>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                            <div class="py-12px border-b" v-if="islandPrice">
                                                <v-row align="center" class="row--xs txt txt--xs">
                                                    <v-col cols="6">
                                                        <span>추가 배송비 (도서산간)</span>
                                                    </v-col>
                                                    <v-col cols="6" class="font-weight-medium text-right">
                                                        <span class="txt--dark"> {{ islandPrice.format() }} 원 </span>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                            <div class="py-12px border-b" v-if="order?.point">
                                                <v-row align="center" class="row--xs txt txt--xs">
                                                    <v-col cols="6">
                                                        <span>사용 적립금</span>
                                                    </v-col>
                                                    <v-col cols="6" class="font-weight-medium text-right">
                                                        <span class="txt--dark"> {{ order?.point?.format?.() }} 원 </span>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                            <div class="py-12px border-b">
                                                <v-row align="center" class="row--xs txt txt--xs">
                                                    <v-col cols="6">
                                                        <span>총 결제금액</span>
                                                    </v-col>
                                                    <v-col cols="6" class="font-weight-medium text-right">
                                                        <span class="primary--text">
                                                            <strong class="txt--lg">{{ totalPrice.format() }}</strong> 원
                                                        </span>
                                                    </v-col>
                                                </v-row>
                                            </div>

                                            <div class="pt-16px">
                                                <v-row class="row--xs">
                                                    <v-col cols="12" sm="6" lg="12">
                                                        <v-btn block outlined color="primary" to="/" class="v-size--xx-large" v-bind="{ loading }">취소하기</v-btn>
                                                    </v-col>
                                                    <v-col cols="12" sm="6" lg="12">
                                                        <v-btn block color="primary" class="v-size--xx-large" v-bind="{ loading }" @click="pay">{{ $route.query.deliveryType === "regular" ? "정기배송 신청하기" : "결제하기" }}</v-btn>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </div>
                                    </v-card>
                                </template>
                            </page-section>
                            <div class="fixed-contents__fixed-con"></div>
                            <!-- // 결제상세 -->
                        </v-col>
                    </v-row>
                </div>
            </div>
        </page-section>

        <v-fade-transition>
            <v-overlay v-show="loading" color="white">
                <v-progress-circular indeterminate size="100" width="5" color="primary" />
            </v-overlay>
        </v-fade-transition>

        <!-- 무통장 입금 모달 -->
        <modal-order-bank ref="modal-order-bank" v-model="order" @pay="submit" :totalPrice="totalPrice"> </modal-order-bank>
        <!-- 쿠폰 사용 -->
        <!-- <div>
            쿠폰 사용 (상품 / 주문쿠폰)
            <input type="number" onkeydown="return event.keyCode !== 69" :value="couponPrice" />
            <span class="currency-unit">원</span>
            <button class="button button--mouse" type="button" @click="openModalUseCoupon">
                <span>쿠폰사용</span>
            </button>
            <span class="left-text">사용가능 쿠폰 : {{ $refs["modal-use-coupon"] ? $refs["modal-use-coupon"].coupons.length : 0 }}장</span>
        </div> -->
        <!-- 쿠폰사용 모달 -->
        <!-- <modal-use-coupon ref="modal-use-coupon" v-model="coupons" :carts="carts" :deliveryPrice="deliveryPrice"> </modal-use-coupon> -->

        <nice-pay ref="nicePay" />
        <nice-pay-u ref="nicePayU" />
        <simple-password-check ref="simplePasswordCheck" @submit="(simplePassword) => submit(simplePassword)"/>
    </client-page>
</template>

<script>
import api from "@/api";
import detect from "@/plugins/detect.js";
import { mapActions, mapState } from "vuex";

import NicePay from "@/components/plugins/nice/nice-pay.vue";
import NicePayU from "@/components/plugins/nice/nice-pay-u.vue";
import ClientPage from "@/components/client/templates/client-page.vue";
import OrderCarts from "@/components/client/shop/order/order-carts.vue";
import OrderPoint from "@/components/client/shop/order/order-point.vue";
import OrderSender from "@/components/client/shop/order/order-sender.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import TitWrapLine from "@/components/client/dumb/tit-wrap-line.vue";
import OrderReceiver from "@/components/client/shop/order/order-receiver.vue";
import VerticalTable from "@/components/dumb/vertical-table.vue";
import ModalUseCoupon from "../../../components/client/shop/modal-use-coupon.vue";
import ModalOrderBank from "../../../components/client/shop/modal-order-bank.vue";
import VerticalFormTable from "@/components/dumb/vertical-form-table.vue";
import SimplePasswordCheck from "@/components/client/mypage/card/simple-password-check.vue"
import DestinationSelectDialog from "@/components/client/shop/destinations/destination-select-dialog.vue";
import cryptoAes from '@/plugins/crypto-aes';

export default {
    components: {
        NicePay,
        NicePayU,
        ClientPage,
        OrderCarts,
        OrderPoint,
        OrderSender,
        PageSection,
        TitWrapLine,
        OrderReceiver,
        VerticalTable,
        ModalUseCoupon,
        ModalOrderBank,
        VerticalFormTable,
        SimplePasswordCheck,
        DestinationSelectDialog,
    },
    data: () => ({
        detect,

        loading: false,

        pickup: { _carts: [] },
        carts: [],
        coupons: [],

        order: {
            _coupons: [],
            _carts: [],
            point: 0,
            sender: {
                name: null,
                phone: null,
                email: null,
                postcode: null,
                address1: null,
                address2: null,
            },
            receiver: {
                _id: null,
                basic: true,
                title: null,
                name: null,
                phone: null,
                email: null,
                postcode: null,
                address1: null,
                address2: null,
            },
            account: {
                senderName: null,

                code: null,
                accountNumber: null,
                accountHolder: null,
            },
            cashRecipt: {
                enabled: false,
                phone: null,
            },
            requestMessage: null,
            paymentMethod: "card",
        },
    }),
    computed: {
        ...mapState(["user", "islands", "shippings"]),

        ///////////////////////////////////////////////////////////////////////
        // 액세스 토큰
        ///////////////////////////////////////////////////////////////////////
        accessToken() {
            return this.$store.state.accessToken;
        },

        ///////////////////////////////////////////////////////////////////////
        // 우편번호
        ///////////////////////////////////////////////////////////////////////
        postcode() {
            return this.order.receiver.postcode;
        },
        ///////////////////////////////////////////////////////////////////////
        // 상품가 합계
        ///////////////////////////////////////////////////////////////////////
        productPrice() {
            return this.$getProductPrice(this.carts);
        },
        ///////////////////////////////////////////////////////////////////////
        // 할인가 합계
        ///////////////////////////////////////////////////////////////////////
        discountPrice() {
            return this.$getDiscountPrice(this.carts);
        },
        ///////////////////////////////////////////////////////////////////////
        // 쿠폰할인금액 합계
        ///////////////////////////////////////////////////////////////////////
        couponPrice() {
            return this.$getCouponPrice(this.carts, this.coupons);
        },
        ///////////////////////////////////////////////////////////////////////
        // 배송비 합계
        ///////////////////////////////////////////////////////////////////////
        deliveryPrice() {
            return this.$getDeliveryPrice(this.carts, this.shippings);
        },
        ///////////////////////////////////////////////////////////////////////
        // 도서산간비용 합계
        ///////////////////////////////////////////////////////////////////////
        islandPrice() {
            return this.$getIslandPrice(this.carts, this.islands, this.postcode);
        },
        ///////////////////////////////////////////////////////////////////////
        // 최대사용가능포인트
        ///////////////////////////////////////////////////////////////////////
        maxUsablePoint() {
            const priceLeft = this.productPrice + this.deliveryPrice + this.islandPrice - this.discountPrice - this.couponPrice || 0;
            const pointLeft = this.user?.point || 0;
            return [priceLeft, pointLeft].sort((a, b) => a - b).shift();
        },

        ///////////////////////////////////////////////////////////////////////
        // 총 결제 금액
        ///////////////////////////////////////////////////////////////////////
        totalPrice() {
            return this.productPrice + this.deliveryPrice + this.islandPrice - this.discountPrice - this.couponPrice - this.order.point;
        }
    },
    mounted() {
        this.reroute().then(this.init);
    },
    watch: {
        coupons() {
            this.order._coupons = this.coupons.map(({ _id }) => _id);
        },
        accessToken() {
            this.reroute();
        },
    },
    methods: {
        ...mapActions(["getUser", "getIslands", "getShippings"]),

        openModalUseCoupon() {
            this.$refs["modal-use-coupon"].open();
        },

        openModalOrderBank() {
            this.$refs["modal-order-bank"].open();
        },

        async reroute() {
            try {
                if (!this.accessToken) throw new Error("로그인 후 사용하실 수 있습니다");
            } catch (error) {
                this.$handleError(error);
                this.$router.replace("/login");
            }
        },

        async init() {
            if (!this.accessToken) return;

            if (this.loading) return;
            else this.loading = true;

            if (this.$route.query.deliveryType === "regular") {
                this.order.paymentMethod = "regular";
            }
            try {
                if (!this.$store.state.pickup.length) {
                    alert("구매할 상품을 선택해주세요");
                    this.$router.go(-1);
                    return;
                }

                this.order._carts = this.$store.state.pickup;
                this.carts = await this.getCarts();

                this.getUser().then(() =>
                    this.$nextTick(() => {
                        this.order.sender = { ...(this.order?.sender || {}), ...(this.user || {}) };
                    })
                );
                this.getIslands();
                this.getShippings();

                this.$nextTick(() => {
                    this.onScrollFixed();
                });
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },

        async validates() {
            try {
                if (!this.order?.receiver?.name) throw new Error("수취인명을 입력해주세요");
                if (!this.order?.receiver?.phone) throw new Error("수취인 연락처를 입력해주세요");
                if (!this.order?.receiver?.address2) throw new Error("수취인 주소를 입력해주세요");

                if (!this.order?.receiver?._id) {
                    if (!this.order?.receiver?.title) throw new Error("배송지명을 입력해주세요");
                }

                return true;
            } catch (error) {
                this.$handleError(error);
                return false;
            }
        },

        async pay() {
            if (this.loading) return;
            else this.loading = true;

            if (!(await this.validates())) {
                this.loading = false;
                return;
            }

            try {
                const { receiver } = this.order || {};
                if (receiver && !receiver?._id) {
                    const { destination } = await api.v1.me.destinations.post(receiver);
                    this.order.receiver = destination;
                }

                switch (this.order.paymentMethod) {
                    case "account": {
                        this.openModalOrderBank();
                        break;
                    }
                    case "bank": {
                        this.submit();
                        break;
                    }
                    case "card": {
                        this.submit();
                        break;
                    }
                    case "easy": {
                        this.submit();
                        break;
                    }
                    case "regular": {
                        this.$refs.simplePasswordCheck.open()
                        break;
                    }
                }
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },

        async submit(simplePassword) {
            try {
                let { order } = await api.v1.shop.orders.post({...this.order, simplePassword: simplePassword ? cryptoAes.encrypt(simplePassword) : null, deliveryCycle: this.$route.query.deliveryCycle || null});
            
                if (order.totalPrice) {
                    switch (order.paymentMethod) {
                        case "account": {
                            order = (await api.v1.shop.orders.process.post(order))?.order;
                            this.$router.push({ path: "/shop/result", query: { _order: order?._id } });
                            break;
                        }
                        case "bank": {
                            this.$refs.nicePay.payMethod = "BANK";
                            this.$refs.nicePay.amt = order.totalPrice;
                            this.$refs.nicePay.goodsName = this.carts[0].product.name;
                            this.$refs.nicePay.moid = order.orderNo;
                            this.$refs.nicePay.buyerName = order.sender.name;
                            this.$refs.nicePay.buyerEmail = order.sender.email;
                            this.$refs.nicePay.buyerTel = order.sender.phone;
                            this.$refs.nicePay.nicepayStart();
                            break;
                        }
                        case "card": {
                            this.$refs.nicePay.payMethod = "CARD";
                            this.$refs.nicePay.amt = order.totalPrice;
                            this.$refs.nicePay.goodsName = this.carts[0].product.name;
                            this.$refs.nicePay.moid = order.orderNo;
                            this.$refs.nicePay.buyerName = order.sender.name;
                            this.$refs.nicePay.buyerEmail = order.sender.email;
                            this.$refs.nicePay.buyerTel = order.sender.phone;
                            this.$refs.nicePay.nicepayStart();
                            break;
                        }
                        case "easy": {
                            this.$refs.nicePayU.buyerUsername = order.sender.username;
                            this.$refs.nicePayU.buyerName = order.sender.name;
                            this.$refs.nicePayU.buyerTel = order.sender.phone;
                            this.$refs.nicePayU.buyerEmail = order.sender.email;
                            this.$refs.nicePayU.moid = order.orderNo;
                            this.$refs.nicePayU.amt = order.totalPrice;
                            this.$refs.nicePayU.goodsName = this.carts[0].product.name;
                            this.$refs.nicePayU.receiverName = order.receiver.name;
                            this.$refs.nicePayU.receiverTel = order.receiver.phone;
                            this.$refs.nicePayU.receiverPostcode = order.receiver.postcode;
                            this.$refs.nicePayU.receiverAddress1 = order.receiver.address1;
                            this.$refs.nicePayU.receiverAddress2 = order.receiver.address2;

                            this.$refs.nicePayU.submit();
                            break;
                        }
                        case "regular": {
                            let data = {
                                orderNo: order.orderNo,
                                amount: order.totalPrice,
                                goodsName: this.carts[0].product.name
                            }
                            let { _order, resCode, resMessage } = await api.plugins.nice.billing.response(data)
                            this.$router.push({ path: "/shop/result", query: { _order, resCode, resMessage } });
                            break;
                        }
                    }
                } else {
                    order = (await api.v1.shop.orders.process.post(order))?.order;
                    this.$router.push({ path: "/shop/result", query: { _order: order?._id } });
                }
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
        },

        getCarts() {
            return new Promise(async (resolve, reject) => {
                try {
                    var res = await this.$http.get("/api/v1/me/carts", {
                        params: {
                            _carts: this.$store.state.pickup,
                            deliveryType: this.$route.query.deliveryType
                        },
                    });

                    resolve(res.data.carts);
                } catch (error) {
                    reject(error);
                }
            });
        },

        onScrollFixed() {
            // Sticky
            let scrollPosition = 0,
                absoluteTop = 0,
                ticking = false,
                mobileWidth = 1024;
            let timeout;

            var fixedSection = document.querySelector(".fixed-section");
            var fixedContentsFixed = document.querySelector(".fixed-contents__fixed");
            var fixedSectionTop = fixedSection.getBoundingClientRect().top;
            var fixedSectionHeight = fixedSection.clientHeight;
            var fixedContentsFixedHeight = fixedContentsFixed.clientHeight;
            var fixedSectionBottom = fixedSectionTop + fixedSectionHeight - fixedContentsFixedHeight;
            var fixedContentsFixedTranslate = fixedSectionHeight - fixedContentsFixedHeight - 12 - 24;

            function animate() {
                scrollPosition = window.scrollY || window.pageYOffset;
                absoluteTop = window.pageYOffset + fixedSection.getBoundingClientRect().top + 12;

                // PC
                if (window.innerWidth > mobileWidth) {
                    if (scrollPosition < absoluteTop) {
                        fixedSection.classList.remove("active");
                    } else {
                        fixedSection.classList.add("active");
                    }
                    if (scrollPosition > fixedSectionBottom) {
                        fixedSection.classList.add("bottom");
                        fixedContentsFixed.style.transform = "translate(0px," + fixedContentsFixedTranslate + "px)";
                    } else {
                        fixedSection.classList.remove("bottom");
                        fixedContentsFixed.style = "";
                    }
                }
            }

            animate();

            window.addEventListener("scroll", function(e) {
                if (!ticking) {
                    window.requestAnimationFrame(function() {
                        animate();
                        ticking = false;
                    });
                    ticking = true;
                }
            });
            window.addEventListener("resize", resize, false);
            function resize() {
                if (!timeout) {
                    timeout = setTimeout(function() {
                        timeout = null;
                        handle();
                    }, 66);
                }
            }
            function handle() {
                let windowWidth = window.outerWidth;
                if (windowWidth > mobileWidth) {
                    animate();
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.fixed-contents {
    &__fixed-con {
        display: none;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
    .fixed-section {
        position: relative;
        display: block;
        &.active {
            .fixed-contents {
                &__fixed {
                    position: fixed;
                    top: 0;
                    & + .fixed-contents__fixed-con {
                        display: block;
                    }
                }
            }
        }
        &.bottom {
            .fixed-contents {
                &__fixed {
                    position: absolute !important;
                    top: auto !important;
                    left: auto !important;
                }
            }
        }
    }
    .fixed-contents {
        position: relative;
        &__fixed,
        &__fixed-con {
            width: 282px;
        }
    }
}
@media (min-width: 1200px) {
}
</style>
