var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('nav', {
    staticClass: "product-categories"
  }, [_c('ul', {
    staticClass: "product-category"
  }, [_vm._m(0), _vm._l(_vm.categories, function (depth1) {
    return _c('li', {
      staticClass: "product-category__list"
    }, [_c('a', {
      staticClass: "product-category__list-link",
      attrs: {
        "data-toggle": _vm.isExpanded(depth1)
      },
      on: {
        "click": function ($event) {
          return _vm.input(depth1);
        }
      }
    }, [_vm.hasChildren(depth1) ? _c('i', {
      staticClass: "arrow"
    }) : _vm._e(), _c('span', [_vm._v(_vm._s(depth1.name))])]), _vm.hasChildren(depth1) ? _c('ul', {
      staticClass: "product-category__level product-category__level--1"
    }, _vm._l(depth1.children, function (depth2) {
      return _c('li', [_c('a', {
        staticClass: "product-category__level-link",
        attrs: {
          "data-toggle": _vm.isExpanded(depth2)
        },
        on: {
          "click": function ($event) {
            return _vm.input(depth2);
          }
        }
      }, [_c('span', [_vm._v(_vm._s(depth2.name))])]), _vm.hasChildren(depth2) ? _c('ul', {
        staticClass: "product-category__level product-category__level--2"
      }, _vm._l(depth2.children, function (depth3) {
        return _c('li', {
          on: {
            "click": function ($event) {
              return _vm.input(depth3);
            }
          }
        }, [_c('a', {
          class: {
            on: _vm.category == depth3
          }
        }, [_c('span', [_vm._v(_vm._s(depth3.name))])])]);
      }), 0) : _vm._e()]);
    }), 0) : _vm._e()]);
  })], 2)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', {
    staticClass: "product-category__list"
  }, [_c('a', {
    staticClass: "product-category__list-link",
    attrs: {
      "href": "?"
    }
  }, [_c('span', [_vm._v("카테고리 전체")])])]);

}]

export { render, staticRenderFns }