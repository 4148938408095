<template>
    <ul>
        <li v-for="{ text, to, attrs } in items" :key="text">
            <router-link v-bind="{ ...attrs, to }"> {{ text }} </router-link>
        </li>
    </ul>
</template>

<script>
export default {
    props: {
        value: { type: Number, default: 24 },
    },
    computed: {
        items() {
            const { ...query } = this.$route.query;

            delete query.best;
            delete query.newly;
            delete query.byPrice;

            return [
                {
                    text: "베스트",
                    to: { query: { ...query, best: true, page: 1 } },
                    class: { on: this.$route.query.best },
                },
                {
                    text: "신상품",
                    to: { query: { ...query, newly: true, page: 1 } },
                    class: { on: this.$route.query.newly },
                },
                {
                    text: "높은 가격순",
                    to: { query: { ...query, byPrice: -1, page: 1 } },
                    class: { on: this.$route.query.byPrice == -1 },
                },
                {
                    text: "낮은 가격순",
                    to: { query: { ...query, byPrice: 1, page: 1 } },
                    class: { on: this.$route.query.byPrice == 1 },
                },
            ].map(({ text, to, ...attrs }) => ({ text, to, attrs }));
        },
    },
};
</script>

<style lang="scss" scoped>
.on {
    color: var(--v-primary-base);
}
</style>
