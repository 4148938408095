<template>
    <client-page>
        <page-section class="page-section--first page-section--last">
            <template slot="tit">{{ category?.name || "전체보기" }}</template>

            <!-- 상품 리스트 -->
            <section class="product-lists">
                <div class="product-lists-head">
                    <div class="product-lists__total">
                        <span>
                            <em>{{ summary.totalCount.format() }}</em> 개의 상품이 있습니다.
                        </span>
                    </div>
                    <div class="product-lists__order">
                        <div class="divider-group">
                            <product-list-sort />
                        </div>
                        <div class="product-lists__select">
                            <product-list-limit v-model="limit" />
                        </div>
                    </div>
                </div>

                <div class="product-lists-body">
                    <shop-product-items>
                        <shop-product-item v-for="(product, index) in products" :key="index" :value="product" />
                    </shop-product-items>

                    <div class="product-lists__pagination">
                        <pagination-component :value="page" :count="pageCount" @input="(page) => $router.push({ query: { ...$route.query, page } })"></pagination-component>
                    </div>
                </div>
            </section>
        </page-section>
    </client-page>
</template>

<script>
import api from "@/api";
import { mapState } from "vuex";

import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import ProductListSort from "@/components/client/shop/products/product-list-sort.vue";
import ShopProductItem from "@/components/client/shop/shop-product-item.vue";
import ShopProductItems from "@/components/client/shop/shop-product-items.vue";
import ProductListLimit from "@/components/client/shop/products/product-list-limit.vue";
import PaginationComponent from "../../../components/client/pagination-component.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        ProductListSort,
        ShopProductItem,
        ShopProductItems,
        ProductListLimit,
        PaginationComponent,
    },
    metaInfo() {
        return Object.assign({}, this.seo);
    },
    data: () => ({
        products: [],

        limit: 24,
        summary: { totalCount: 0 },

        loading: false,

        seo: {},
    }),
    computed: {
        ...mapState(["categories"]),
        category() {
            return (this.categories || []).find(({ code }) => code == this.$route.query.category);
        },
        items() {
            return [...this.products].map((item, index) => ({ index: this.summary.totalCount - (this.page - 1) * this.limit - index, ...item }));
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;
            delete query.category;
            query._category = this.category?._id;
            if (!query._category) delete query._category;
            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
        category() {
            if (this.category) this.seo = this.category.seo && this.category.seoEnabled ? this.category.seo : {};
        },
    },
    methods: {
        async init() {
            try {
                this.products = [];
                this.summary.totalCount = 0;
                this.loading = false;

                this.search();
            } catch (error) {
                console.error(error);
            }
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { skip, limit, params } = this;
                var { summary, products } = await api.v1.shop.products.gets({
                    headers: { skip, limit },
                    params,
                });

                this.summary = summary;
                this.products = products;
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
