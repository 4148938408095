var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('page-section', [_c('div', {
    staticClass: "tit-wrap text-center"
  }, [_c('h2', {
    staticClass: "tit tit--md"
  }, [_c('span', {
    staticClass: "primary--text"
  }, [_vm._v(_vm._s(_vm.$route.query.searchValue))]), _vm._v("에 대한 검색결과")])]), _c('section', {
    staticClass: "product-lists"
  }, [_c('div', {
    staticClass: "product-lists-head"
  }, [_c('div', {
    staticClass: "product-lists__total"
  }, [_c('em', [_vm._v(_vm._s(_vm.summary.totalCount.format()))]), _vm._v(" 개의 상품이 있습니다. ")]), _c('div', {
    staticClass: "product-lists__order"
  }, [_c('div', {
    staticClass: "divider-group"
  }, [_c('ul', [_c('li', [_c('a', {
    class: {
      on: _vm.$route.query.best
    },
    on: {
      "click": function ($event) {
        return _vm.search({
          best: true,
          newly: undefined,
          byPrice: undefined
        });
      }
    }
  }, [_vm._v("베스트")])]), _c('li', [_c('a', {
    class: {
      on: _vm.$route.query.newly
    },
    on: {
      "click": function ($event) {
        return _vm.search({
          best: undefined,
          newly: true,
          byPrice: undefined
        });
      }
    }
  }, [_vm._v("신상품")])]), _c('li', [_c('a', {
    class: {
      on: _vm.$route.query.byPrice == -1
    },
    on: {
      "click": function ($event) {
        return _vm.search({
          best: undefined,
          newly: undefined,
          byPrice: -1
        });
      }
    }
  }, [_vm._v("높은 가격순")])]), _c('li', [_c('a', {
    class: {
      on: _vm.$route.query.byPrice == 1
    },
    on: {
      "click": function ($event) {
        return _vm.search({
          best: undefined,
          newly: undefined,
          byPrice: 1
        });
      }
    }
  }, [_vm._v("낮은 가격순")])])])]), _c('div', {
    staticClass: "product-lists__select"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.limit,
      expression: "limit"
    }],
    staticClass: "product-lists__select-option",
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.limit = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }
    }
  }, [_c('option', {
    domProps: {
      "value": 20
    }
  }, [_vm._v("20개 보기")])])])])]), _c('div', {
    staticClass: "product-lists-body"
  }, [_c('shop-product-items', _vm._l(_vm.products, function (product, index) {
    return _c('shop-product-item', {
      key: index,
      attrs: {
        "value": product
      }
    });
  }), 1), _c('div', {
    staticClass: "product-lists__pagination"
  }, [_c('pagination-component', {
    attrs: {
      "count": _vm.pageCount
    },
    on: {
      "input": _vm.search
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)], 1)])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }