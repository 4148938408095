var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-dialog', {
    attrs: {
      "title": "간편비밀번호확인",
      "persistent": "",
      "width": "400"
    },
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_vm.type === 'check' ? [_c('div', {
    staticClass: "txt txt--xs txt--dark font-weight-medium text-center pt-12px pt-md-0"
  }, [_vm._v("간편비밀번호를 입력해주세요.")]), _c('div', {
    staticClass: "pt-12px mx-auto",
    staticStyle: {
      "max-width": "328px"
    }
  }, [_c('v-otp-input', {
    model: {
      value: _vm.simplePassword,
      callback: function ($$v) {
        _vm.simplePassword = $$v;
      },
      expression: "simplePassword"
    }
  })], 1), _c('div', {
    staticClass: "btn-wrap"
  }, [_c('v-row', {
    staticClass: "row--sm"
  }, [_c('v-col', [_c('v-btn', {
    staticClass: "v-size--xx-large",
    attrs: {
      "block": "",
      "outlined": "",
      "color": "grey"
    }
  }, [_vm._v("이전")])], 1), _c('v-col', [_c('v-btn', {
    staticClass: "v-size--xx-large",
    attrs: {
      "block": "",
      "color": "primary"
    },
    on: {
      "click": _vm.check
    }
  }, [_vm._v("다음")])], 1)], 1)], 1)] : _vm._e(), _vm.type === 'order' ? [_c('div', {
    staticClass: "txt txt--xs txt--dark font-weight-medium text-center py-20px py-md-0"
  }, [_vm._v("정기배송을 진행하시겠습니까?")]), _c('div', {
    staticClass: "btn-wrap"
  }, [_c('v-row', {
    staticClass: "row--sm"
  }, [_c('v-col', [_c('v-btn', {
    staticClass: "v-size--xx-large",
    attrs: {
      "block": "",
      "outlined": "",
      "color": "grey"
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v("취소")])], 1), _c('v-col', [_c('v-btn', {
    staticClass: "v-size--xx-large",
    attrs: {
      "block": "",
      "color": "primary"
    },
    on: {
      "click": _vm.order
    }
  }, [_vm._v("확인")])], 1)], 1)], 1)] : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }