var render = function render(){
  var _ref, _vm$meta;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('div', {
    staticClass: "text-center border-md-r"
  }, [_c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-medium"
  }, [_vm._v("구매만족도")]), _c('div', {
    staticClass: "font-size-20 font-size-md-36 font-weight-bold"
  }, [_vm._v(_vm._s((_ref = ((_vm$meta = _vm.meta) === null || _vm$meta === void 0 ? void 0 : _vm$meta.star__average) || 0) === null || _ref === void 0 ? void 0 : _ref.toFixed(1)))]), _c('v-rating', {
    attrs: {
      "half-increments": "",
      "readonly": "",
      "color": "primary",
      "background-color": "primary",
      "value": _vm.meta.star__average
    }
  }), _c('div', {
    staticClass: "txt txt--xs txt--light"
  }, [_vm._v("(" + _vm._s(_vm.meta.count__total) + "건)")])], 1)]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "9"
    }
  }, [_c('div', {
    staticClass: "pl-md-36px pr-md-80px font-size-14 grey--text text--lighten-1"
  }, [_vm._l([5, 4, 3, 2, 1], function (star) {
    return [_c('v-row', {
      key: star,
      staticClass: "row--sm",
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('div', {
      staticStyle: {
        "width": "36px"
      }
    }, [_vm._v(_vm._s(star) + "점")])]), _c('v-col', {
      attrs: {
        "cols": ""
      }
    }, [_c('v-progress-linear', {
      attrs: {
        "rounded": "",
        "color": _vm.meta['count__star' + star] && _vm.meta['count__star' + star] == _vm.star__highest ? 'red' : 'grey lighten-1',
        "height": "10",
        "value": _vm.stars__percentage[star]
      }
    })], 1), _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('div', {
      staticClass: "text-right",
      class: {
        'red--text': _vm.meta['count__' + star] && _vm.meta['count__' + star] == _vm.star__highest
      },
      staticStyle: {
        "width": "48px"
      }
    }, [_vm._v(_vm._s(_vm.stars__percentage[star].toFixed(0)) + "%")])])], 1)];
  })], 2)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }