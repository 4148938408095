var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-select', _vm._b({
    staticClass: "mw-md-160px",
    attrs: {
      "dense": "",
      "outlined": "",
      "hide-details": "",
      "item-text": "text",
      "item-value": "value"
    },
    on: {
      "change": function (data) {
        return _vm.$emit('input', data);
      }
    }
  }, 'v-select', {
    value: _vm.value,
    items: _vm.items
  }, false));

}
var staticRenderFns = []

export { render, staticRenderFns }