var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-dialog', {
    attrs: {
      "title": "배송지 선택하기",
      "width": "720"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('div', _vm._b({
    staticClass: "border-t border-2 border-dark"
  }, 'div', {
    items: _vm.items,
    loading: _vm.loading
  }, false), _vm._l(_vm.items, function (item) {
    return _c('v-card', {
      key: item === null || item === void 0 ? void 0 : item._id
    }, [_c('div', {
      staticClass: "py-16px"
    }, [_c('v-row', {
      staticClass: "ma-n8px",
      attrs: {
        "align-md": "center"
      }
    }, [_c('v-col', {
      staticClass: "pa-8px"
    }, [_c('v-card', {
      attrs: {
        "color": "transparent"
      }
    }, [_c('div', {
      staticClass: "txt txt--sm txt--dark font-weight-medium"
    }, [_c('v-row', {
      staticClass: "row--xs",
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_vm._v(" " + _vm._s(item.title) + " (" + _vm._s(item.name) + ") ")]), item.isDefault ? _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-chip', {
      attrs: {
        "x-small": "",
        "color": "primary"
      }
    }, [_vm._v("기본배송지")])], 1) : _vm._e()], 1)], 1), _c('div', {
      staticClass: "txt txt--xs txt--light mt-4px mt-md-8px"
    }, [_c('div', [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : item.phone))]), _c('div', [_vm._v(_vm._s(item.address))])])])], 1), _c('v-col', {
      staticClass: "pa-8px",
      attrs: {
        "cols": "12",
        "md": "auto"
      }
    }, [_c('div', {
      staticClass: "w-md-150px"
    }, [_c('v-row', {
      staticClass: "row--xs"
    }, [_c('v-col', {
      staticClass: "mw-100 flex-grow-1 flex-shrink-0",
      attrs: {
        "cols": "4",
        "md": "12"
      }
    }, [_c('v-btn', {
      attrs: {
        "block": "",
        "outlined": "",
        "color": "primary"
      },
      on: {
        "click": function ($event) {
          return _vm.select(item);
        }
      }
    }, [_vm._v("선택")])], 1)], 1)], 1)])], 1)], 1), _c('v-divider')], 1);
  }), 1), _c('div', {
    staticClass: "pagination-wrap pagination-wrap--sm"
  }, [_c('v-pagination', {
    attrs: {
      "length": _vm.pageCount,
      "total-visible": 11
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }