<template>
    <!-- 상품 카테고리 -->
    <nav class="product-categories">
        <ul class="product-category">
            <li class="product-category__list">
                <a class="product-category__list-link" href="?"><span>카테고리 전체</span></a>
            </li>
            <li v-for="depth1 in categories" class="product-category__list">
                <a class="product-category__list-link" @click="input(depth1)" :data-toggle="isExpanded(depth1)"><i v-if="hasChildren(depth1)" class="arrow"></i><span>{{ depth1.name }}</span></a>
                <ul v-if="hasChildren(depth1)" class="product-category__level product-category__level--1">
                    <li v-for="depth2 in depth1.children">
                        <a class="product-category__level-link" @click="input(depth2)" :data-toggle="isExpanded(depth2)"><span>{{ depth2.name }}</span></a>
                        <ul v-if="hasChildren(depth2)" class="product-category__level product-category__level--2">
                            <li v-for="depth3 in depth2.children" @click="input(depth3)"><a :class="{ on: category == depth3}"><span>{{ depth3.name }}</span></a></li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ul>
    </nav>
</template>

<script>
export default {
    props: {
        value: { type: Object, default: null }
    },
    data: () => {
        return {
            category: null,
            categories: []
        };
    },
    created(){
        this.init();
    },
    methods: {
        async init(){
            try{
                await this.getCategories();

                if(this.$route.query.category){
                    this.category = this.findCategory(this.$route.query.category);
                    this.$emit('input', this.category);
                }
            }
            catch(error){
                console.error(error);
            }
        },

        input(category){
            this.category = category;
            this.$emit('input', category);
            this.$router.push({
                query: Object.assign({}, this.$route.query, { category: category.code })
            });
        },

        findCategory(code, categories = null){
            if(categories == null) categories = this.categories;
            for(var i in categories){
                if(code == categories[i].code) return categories[i];
                else if(categories[i].children.length){
                    var result = this.findCategory(code, categories[i].children);
                    if(result) return result;
                }
            }
        },

        hasChildren(category){
            return category.children && category.children.length ? true : false;
        },

        isExpanded(category){
            if(this.category == null) return 'closed';
			return -1 < [].concat(this.category._parents, this.category._id).indexOf(category._id) ? 'opened' : 'closed';
		},

        /////////////////////////////////////////
        // 카테고리 조회
        /////////////////////////////////////////
        getCategories() {
            return new Promise(async (resolve, reject) => {
                try {
                    var res = await this.$http.get('/api/v1/shop/categories');

                    this.categories = res.data.categories;

                    resolve();
                } catch (error) {
                    reject(error);
                }
            });
        },
    },
    watch: {
        value() {
            this.category = this.value;
        },
        "$route.query.category"() {
            this.category = this.findCategory(this.$route?.query?.category);
            this.$emit("input", this.category);
        },

    },
};
</script>
