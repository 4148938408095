<template>
    <div>
        <div class="txt txt--xs py-16px py-sm-24px">
            <tit-wrap-small title="적립금 혜택으로 돈버는 쇼핑!">
                <div class="txt--light" v-if="user?.title">
                    내 회원등급 <span class="primary--text">{{ user?.title }}</span>
                </div>
            </tit-wrap-small>
            <product-benefits-card :_product="product._id" />
        </div>
        <v-divider />
    </div>
</template>

<script>
import { mapState } from "vuex";

import TitWrapSmall from "@/components/client/dumb/tit-wrap-small.vue";
import ProductBenefitsCard from "@/components/client/shop/products/product-benefits-card.vue";

export default {
    components: {
        TitWrapSmall,
        ProductBenefitsCard,
    },
    props: {
        product: { type: Object, default: () => ({}) },
    },
    computed: {
        ...mapState(["user"]),
    },
};
</script>

<style></style>
